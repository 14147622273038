import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import LoaderPageComponent from "src/Component/LoaderPageComponent";
import EnterpriseFormComponent from "./Component/EnterpriseFormComponent";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import EnterpriseOwnerListComponent from "./Component/EnterpriseOwnerListComponent";

function Enterprise() {
    // React
    const [loading, setLoading] = React.useState(true);
    const [enterpriseInfo, setEnterpriseInfo] = React.useState(null);

    // Router
    const {enterprise} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Bar + Info
    React.useEffect(() => {
        if (enterprise) {
            dispatch(barMainLayoutReduxChange({title: 'Éditer une entreprise', urlBack: routingHandlerGetRoutePathname('administrator_enterprises')}));

            routingApiHandlerFetch({
                route: 'api_administrator_enterprise',
                params: {enterprise: enterprise},
                method: 'GET',
                success: (response) => {
                    setEnterpriseInfo(response.data);
                    setTimeout(() => setLoading(false), 750);
                },
                error: (response) => {
                    setTimeout(() => setLoading(false), 750);
                }
            });
        }
        else {
            dispatch(barMainLayoutReduxChange({title: 'Ajouter une entreprise', urlBack: routingHandlerGetRoutePathname('administrator_enterprises')}));
            setLoading(false);
        }
    }, [enterprise]);

    return (
        loading ? <LoaderPageComponent/> :<>
            <EnterpriseFormComponent enterprise={enterpriseInfo}/>
            <br/>
            {enterpriseInfo && <EnterpriseOwnerListComponent enterprise={enterpriseInfo}/>}
        </>
    );
}

export default Enterprise;
