import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import LoaderPageComponent from "src/Component/LoaderPageComponent";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import ServerTabsComponent from "./Component/ServerTabsComponent";

function Server() {
    // React
    const [loading, setLoading] = React.useState(true);
    const [serverInfo, setServerInfo] = React.useState(null);

    // Router
    const {server} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Bar + Info
    React.useEffect(() => {
        if (server) {
            dispatch(barMainLayoutReduxChange({title: 'Éditer une serveur', urlBack: routingHandlerGetRoutePathname('administrator_servers')}));

            routingApiHandlerFetch({
                route: 'api_administrator_server',
                params: {server: server},
                method: 'GET',
                success: (response) => {
                    setServerInfo(response.data);
                    dispatch(barMainLayoutReduxChange({title: 'Éditer une serveur - ' + response.data.name + ' / ' + response.data.ip, urlBack: routingHandlerGetRoutePathname('administrator_servers')}));
                    setTimeout(() => setLoading(false), 750);
                },
                error: () => {
                    setTimeout(() => setLoading(false), 750);
                }
            });
        }
        else {
            dispatch(barMainLayoutReduxChange({title: 'Ajouter une serveur', urlBack: routingHandlerGetRoutePathname('administrator_servers')}));
            setLoading(false);
        }
    }, [server]);

    return (
        loading ? <LoaderPageComponent/> : <><ServerTabsComponent server={serverInfo}/></>
    );
}

export default Server;
