import moment from 'moment';

export const formHandlerInit = (form, setForm) => {
    const parseType = (value, type) => {
        switch (type) {
            case 'text':
            case 'password':
                return value ? value : '';
            case 'integer':
                return value == '0' ? 0 : value ? parseInt(value) : '';
            case 'float':
                return value == '0' ? '0.00' : value ? parseFloat(value).toFixed(2) : '';
            case 'boolean':
                return (value !== true && value !== false) ? '' : value;
            case 'array':
                return value ? value : [];
            case 'date':
                return value ? moment(value).format('YYYY-MM-DD') : '';
            case 'datetime':
                return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
            case 'file':
                return value;
        }
    };

    const handler = function () {
        this.get = (id) => {
            return form[id];
        }

        // VALUE
        this.setValue = (id, value, ignoreType = false) => {
            let newValue = value ?? '';

            if (newValue) {
                if (form[id].type === 'integer') {
                    if ((newValue.toString().match(/^-?\d*$/i) === null)) {
                        newValue = form[id].value;
                    }
                }
                else if (form[id].type === 'float') {
                    if ((newValue.toString().match(/^-?[0-9]*\.?[0-9]*$/i) === null)) {
                        newValue = form[id].value;
                    }
                }
            }

            if (ignoreType) {
                form[id].value = newValue;
            }
            else {
                form[id].value = parseType(newValue, form[id].type);
            }
            setForm({...form});
        }

        this.getValue = (id) => {
            return form[id].value;
        }

        this.reset = () => {
            for (let index in form) {
                form[index].error = '';
                form[index].value = '';
            }

            setForm({...form});
        }

        // ERROR
        this.setError = (id, error) => {
            form[id].error = error;
            setForm({...form});
        }

        this.getError = (id) => {
            return form[id].error;
        }

        this.setErrorApi = (error = {}) => {
            if (error.fields) {
                for (let index1 in form) {
                    for (let index2 in error.fields) {
                        if (index1 === error.fields[index2].reference) form[index1].error = error.fields[index2].message;
                    }
                }
            }

            setForm({...form});
        };

        this.checkValid = () => {
            let count = 0;
            for (let index in form) {
                this.checkValidBy(index);
                if (form[index].error) count++;
            }

            setForm({...form});

            return count;
        };

        this.checkValidBy = (id) => {
            let field = form[id];
            let validation = (field.options && field.options.validation) ? field.options.validation : [];
            let value = field.value;
            let error = '';
            let isError = false;

            if (validation && Array.isArray(validation)) {
                if ((value === '' || value === null || value === NaN || value === 'undefined')) {
                    if (validation.indexOf('required') > -1) {
                        isError = true;
                        error = 'Ce champ est obligatoire.';
                    }
                }
                else {
                    for (let index in validation) {
                        if (isError) break;
                        switch (validation[index]) {
                            case 'required':
                                isError = (Array.isArray(value)) ? value.length < 1 : value.toString().length < 1;
                                error = isError ? 'Ce champ est obligatoire.' : '';
                                break;
                            case 'email':
                                isError = (value.toString().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null);
                                isError = (isError && value.toString().length > 0);
                                error = isError ? 'Cet email n\'est pas valide.' : '';
                                break;
                            case 'float':
                                isError = (value.toString().match(/^-?[0-9]*\.?[0-9]*$/i) === null);
                                isError = (isError && value.toString().length > 0);
                                error = isError ? 'Ce champs doit être un nombre décimal.' : '';
                                break;
                            case 'integer':
                                isError = (value.toString().match(/^-?\d*$/i) === null);
                                isError = (isError && value.toString().length > 0);
                                error = isError ? 'Ce champs doit être un nombre entier.' : '';
                                break;
                            case 'datetime':
                                isError = (value.toString().match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/i) === null);
                                isError = (isError && value.toString().length > 0);
                                error = isError ? 'Ce format de date est invalide.' : '';
                                break;
                            case 'date':
                                isError = (value.toString().match(/^\d{4}-\d{2}-\d{2}$/i) === null);
                                isError = (isError && value.toString().length > 0);
                                error = isError ? 'Ce format de date est invalide.' : '';
                                break;
                            case 'alpha':
                                isError = (value.toString().match(/^[a-zA-Z]+$/i) === null);
                                isError = (isError && value.toString().length > 0);
                                error = isError ? 'Ce champs doit être une valeur alphabétique.' : '';
                                break;
                            case 'numeric':
                                isError = (value.toString().match(/^\d*$/i) === null);
                                isError = (isError && value.toString().length > 0);
                                error = isError ? 'Ce champs doit être une valeur numérique.' : '';
                                break;
                            case 'password-secure':
                                isError = (value.toString().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/i) === null);
                                isError = (isError && value.toString().length > 0);
                                error = isError ? 'Ce champs doit être 8 caractères, avoir au moins 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial.' : '';
                                break;
                        }
                    }
                }
            }

            if ((!!value || value === 0) && !isError && !!field.options && (field.options.min === 0 || !!field.options.min || field.options.max === 0 || !!field.options.max || field.options.equal === 0 || !!field.options.equal)) {
                if (field.type === 'text' || field.type === 'password') {
                    let length = value.toString().length;
                    let min = field.options.min ?? 0;
                    let max = field.options.max ?? 0;
                    let equal = field.options.equal ?? 0;

                    if (length > 0) {
                        if (min !== 0 && max !== 0 && !(value.length >= min && value.length <= max)) {
                            error = 'Ce champ doit être compris entre ' + min + ' caractère' + (min > 1 ? 's' : '') + ' et ' + max + ' caractère' + (max > 1 ? 's' : '') + '.';
                            isError = true;
                        }
                        else if (equal !== 0 && value.length !== equal) {
                            error = 'Ce champ doit être égal à ' + equal + ' caractère' + (equal > 1 ? 's' : '') + '.';
                            isError = true;
                        }
                        else if (max !== 0 && !(value.length <= max)) {
                            error = 'Ce champ ne peut pas dépasser ' + max + ' caractère' + (max > 1 ? 's' : '') + '.';
                            isError = true;
                        }
                        else if (min !== 0 && !(value.length >= min)) {
                            error = 'Ce champ doit contenir au minimun ' + min + ' caractère' + (min > 1 ? 's' : '') + '.';
                            isError = true;
                        }
                    }
                }
                else if (field.type === 'float' || field.type === 'integer') {
                    if (typeof field.options.min !== 'undefined' && value < field.options.min) {
                        error = 'Ce champ doit être supérieur ou égal à ' + field.options.min + '.';
                        isError = true;
                    }
                    else if (typeof field.options.max !== 'undefined' && value > field.options.max) {
                        error = 'Ce champ doit être inférieur ou égal à ' + field.options.max + '.';
                        isError = true;
                    }
                }
            }

            if (value && !isError && field.options && (field.options.inf || field.options.sup)) {
                if (field.type === 'float' || field.type === 'integer') {
                    if (typeof field.options.inf !== 'undefined' && value >= field.options.inf) {
                        error = 'Ce champ doit être inférieur à ' + field.options.inf + '.';
                        isError = true;
                    }
                    if (typeof field.options.sup !== 'undefined' && value <= field.options.sup) {
                        error = 'Ce champ doit être supérieur à ' + field.options.sup + '.';
                        isError = true;
                    }
                }
            }

            if (value && !isError && field.options && field.options.minDate) {
                if (moment(field.options.minDate).isSameOrAfter(moment(value))) {
                    let text;
                    if (field.type === 'date') text = moment(field.options.minDate).format('L')
                    else text = moment(field.options.minDate).format('L LT')
                    error = 'La date ne doit pas être inférieur au ' + text + '.';
                    isError = true;
                }
            }

            if (value && !isError && field.options && field.options.maxDate) {
                if (moment(field.options.maxDate).isSameOrBefore(moment(value))) {
                    let text;
                    if (field.type === 'date') text = moment(field.options.maxDate).format('L')
                    else text = moment(field.options.maxDate).format('L LT')
                    error = 'La date ne doit pas être supérieur au ' + text + '.';
                    isError = true;
                }
            }

            this.setError(id, error);
        }

        // DISABLE
        this.setDisabled = (id, value) => {
            form[id].disabled = value;
            setForm({...form});
        };

        this.setLoading = (value) => {
            for (let index in form) {
                form[index].loading = value;
            }

            setForm({...form});
        };


        // API
        this.setDataApi = (data) => {
            for (let index in data) {
                if (form[index]) {
                    if (data[index] !== null && typeof data[index] === 'object' && !Array.isArray(data[index])) {
                        form[index].value = parseType(data[index].value, form[index].type) ?? '';
                        form[index].valueText = data[index].label ?? '';
                    }
                    else {
                        form[index].value = parseType(data[index], form[index].type) ?? '';
                    }
                }
            }

            setForm({...form});
        };

        this.getDataApi = () => {
            let data = {};
            for (let index in form) {
                data[form[index].id] = parseType(form[index].value, form[index].type);
            }

            return data;
        };
    };
    return new handler();
};

