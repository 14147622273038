import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Box, Fade, Grid, Typography} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Feed, ContactMail, ContactPhone} from "@mui/icons-material";
import FileFieldComponent from "src/Component/FileFieldComponent";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import SelectComponent from "src/Component/SelectComponent";
import {roles} from "src/Config/Role";

function UserFormComponent(props) {
    // React
    const [avatar, setAvatar] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    // Router
    const navigate = useNavigate();
    const {user} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Form
    const [form, setForm] = React.useState({
        email: {
            id: 'email',
            label: 'Email',
            helperText: 'Entrez l\'email de l\'utilisateur.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email']}
        },
        firstname: {
            id: 'firstname',
            label: 'Prénom',
            helperText: 'Entrez le prénom de l\'utilisateur.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        lastname: {
            id: 'lastname',
            label: 'Nom',
            helperText: 'Entrez le nom de l\'utilisateur.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        phone: {
            id: 'phone',
            label: 'Téléphone',
            helperText: 'Entrez le téléphone de l\'utilisateur.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        roles: {
            id: 'roles',
            label: 'Roles',
            helperText: 'Choisissez les roles de l\'utilisateur.',
            type: 'array',
            value: [],
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_administrator_user',
                method: 'POST',
                params: {user: user ?? ''},
                data: handler.getDataApi(),
                success: (response) => {
                    handler.setLoading(false);
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Utilisateur enregistré avec succès.', variant: 'success'}))
                    navigate(routingHandlerGetRoutePathname('administrator_user', {user: response.data.user}));
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    // Bar + Info
    React.useEffect(() => {
        if (props.user) {
            handler.setDataApi(props.user);
            setAvatar(props.user.avatar);
        }
    }, [props.user]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                        <Feed sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Information
                    </Typography>

                    <br/>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <FileFieldComponent
                                name={avatar}
                                urlFile={avatar ? routingApiHandlerGetRoute({route: 'api_administrator_user_avatar', params: {user: user, avatar: avatar}}) : ''}
                                urlUpload={routingApiHandlerGetRoute({route: 'api_administrator_user_avatar_upload', params: {user: user}})}
                                cantUpload={!!user}
                                cantDownload={false}

                                onUploadSuccess={() => {
                                    routingApiHandlerFetch({
                                        route: 'api_administrator_user',
                                        params: {user: user},
                                        method: 'GET',
                                        success: (response) => {
                                            setAvatar(response.data.avatar);
                                        }
                                    });
                                }}
                                onUploadFail={() => {
                                    dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                }}
                                accept="image/jpeg, image/png, image/webp"
                                sx={{height: '185px', width: '185px', maxWidth: '100%', borderRadius: '100px'}}

                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextFieldComponent handler={handler} id={'firstname'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextFieldComponent handler={handler} id={'lastname'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <TextFieldComponent handler={handler} id={'email'} onSubmit={save} adornment={<ContactMail sx={{color: color.textSecondary, fontSize: '20px'}}/>}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <TextFieldComponent handler={handler} id={'phone'} onSubmit={save} adornment={<ContactPhone sx={{color: color.textSecondary, fontSize: '20px'}}/>}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SelectComponent handler={handler} id={'roles'} onSubmit={save} options={roles}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <br/>

                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default UserFormComponent;
