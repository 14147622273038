import React from 'react';
import {Box} from "@mui/system";
import TableComponent from "src/Component/TableComponent";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import {color} from "src/Config/Theme";
import {Edit, Business} from "@mui/icons-material";
import {Fade, Typography} from "@mui/material";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";

const useStyles = {
    avatar: {
        background: color.primaryOpacity,
        borderRadius: '50%',
        overflow: 'hidden',
        fontSize: '12px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: '2px',
        marginLeft: '25px',
    }
};

function Enterprises() {
    // Redux
    const dispatch = useDispatch();

    // Tables
    const columns = [
        {
            name: "logo",
            label: " ",
            options: {filter: false, sort: false}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Link to={routingHandlerGetRoutePathname('administrator_enterprise', {enterprise: row.uuid})}>
                    <ButtonIconComponent
                        background={color.colorEditBackground}
                        backgroundHover={color.colorEditBackgroundHover}
                        color={color.colorEdit}
                        icon={Edit}
                    />
                </Link>
            </>
        );
    };

    // Bar
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'Liste des entreprises',
            canBack: false
        }));
    }, []);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box>
                <TableComponent
                    id={'api_administrator_enterprises'}
                    title={'Entreprises'}
                    columns={columns}
                    height={'calc(100vh - 225px)'}
                    refreshInterval={5000}
                    icon={Business}
                    promiseData={(resolve) => {
                        routingApiHandlerFetch({
                            route: 'api_administrator_enterprises',
                            method: 'GET',
                            success: (response) => {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].actions = getAction(data[index]);
                                    data[index].logo = data[index].logo ? <img
                                        style={{marginTop: '2px', marginLeft: '25px', borderRadius: '25px'}}
                                        src={data[index].logo ? routingApiHandlerGetRoute({route: 'api_administrator_enterprise_logo', params: {enterprise: data[index].uuid, logo: data[index].logo}}) : ''}
                                        alt={data.name}
                                        height={28}
                                        width={28}/> : <Box sx={useStyles.avatar}>
                                        <Typography>{data[index].name[0]}</Typography>
                                    </Box>;
                                }
                                resolve(data);
                            },
                            error: () => {
                                resolve([]);
                                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                            }
                        });
                    }}
                    actionFirst={{
                        label: 'Ajouter une entreprise',
                        link: routingHandlerGetRoutePathname('administrator_enterprise', {enterprise: null})
                    }}
                />
            </Box>
        </Fade>
    );
}

export default Enterprises;
