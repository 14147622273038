import React from 'react';
import {Box} from "@mui/system";
import {useDispatch} from "react-redux";
import {barMainLayoutReduxChange} from "../../Redux/barMainLayoutRedux";

function Vpn() {
    // Redux
    const dispatch = useDispatch();

    // Nav
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'VPN',
            canBack: false
        }));
    }, []);

    return (
        <Box sx={{width: '100%', height: '120vh'}}>
            VPN
        </Box>
    );
}

export default Vpn;
