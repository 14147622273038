import React from 'react';
import {Box} from "@mui/system";
import TableComponent from "src/Component/TableComponent";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {useDispatch} from "react-redux";
import {Edit, Storage, Security, PrivacyTip} from "@mui/icons-material";
import {Link} from "react-router-dom";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import {color} from "src/Config/Theme";
import {Typography} from "@mui/material";

const useStyles = {
    avatar: {
        background: color.primaryOpacity,
        borderRadius: '50%',
        overflow: 'hidden',
        fontSize: '12px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: '2px',
        marginLeft: '25px',
    }
};

function Servers() {
    // React
    const dispatch = useDispatch();

    // Tables
    const customSort = (order) => {
        return (obj1, obj2) => {
            const val1 = obj1.data.props.sort;
            const val2 = obj2.data.props.sort;

            if (val1 < val2) {
                return order === 'asc' ? -1 : 1;
            }
            if (val1 > val2) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        };
    };
    const columns = [
        {
            name: "logo",
            label: "Entreprise",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "ip",
            label: "IP",
            options: {filter: true, sort: true}
        },
        {
            name: "cpu",
            label: "CPU",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "ram",
            label: "RAM",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "disk",
            label: "Disque",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "firewall",
            label: "Firewall",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Link to={routingHandlerGetRoutePathname('administrator_server', {server: row.uuid})}>
                    <ButtonIconComponent
                        background={color.colorEditBackground}
                        backgroundHover={color.colorEditBackgroundHover}
                        color={color.colorEdit}
                        icon={Edit}
                    />
                </Link>
            </>
        );
    };

    // Bar
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'Liste des serveurs',
            canBack: false
        }));
    }, []);

    return (
        <Box>
            <TableComponent
                id={'api_administrator_servers'}
                title={'Serveurs'}
                columns={columns}
                height={'calc(100vh - 225px)'}
                refreshInterval={5000}
                icon={Storage}
                promiseData={(resolve) => {
                    routingApiHandlerFetch({
                        route: 'api_administrator_servers',
                        method: 'GET',
                        success: (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].actions = getAction(data[index]);
                                data[index].cpu = <span sort={data[index].cpu} style={{color: data[index].cpu < 50 ? color.colorSuccess : (data[index].cpu < 75 ? color.colorWarning : color.colorError)}}>{data[index].cpu} %</span>;
                                data[index].ram = <span sort={data[index].ram} style={{color: data[index].ram < 50 ? color.colorSuccess : (data[index].ram < 75 ? color.colorWarning : color.colorError)}}>{data[index].ram} %</span>;
                                data[index].disk = <span sort={data[index].disk} style={{color: data[index].disk < 50 ? color.colorSuccess : (data[index].disk < 75 ? color.colorWarning : color.colorError)}}>{data[index].disk} %</span>;
                                data[index].firewall = data[index].hasFirewall ? <Security sort={'0'} sx={{color: color.colorSuccess + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/> : <PrivacyTip sort={'1'} sx={{color: color.colorError + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;

                                data[index].logo = data[index].logo ? <img
                                    sort={data[index].enterprise}
                                    style={{marginTop: '2px', marginLeft: '25px', borderRadius: '25px'}}
                                    src={data[index].logo ? routingApiHandlerGetRoute({route: 'api_administrator_servers_logo', params: {logo: data[index].logo}}) : ''}
                                    alt={data.name}
                                    height={28}
                                    width={28}/> : <Box sort={data[index].enterprise} sx={useStyles.avatar}>
                                    <Typography>{data[index].name[0]}</Typography>
                                </Box>;
                            }
                            resolve(data);
                        },
                        error: () => {
                            resolve([]);
                        }
                    });
                }}
                actionFirst={{
                    label: 'Ajouter un serveur',
                    link: routingHandlerGetRoutePathname('administrator_server', {server: null})
                }}
            />
        </Box>
    );
}

export default Servers;
