import {createSlice} from '@reduxjs/toolkit';

const tableRedux = createSlice({
    name: 'tableRedux',
    initialState: {
        id: null,
        withCache: null,
    },
    reducers: {
        tableReduxReload: (state, action) => {
            state.id = action.payload.id;
            state.withCache = action.payload.withCache;
        }
    },
});

export const {tableReduxReload} = tableRedux.actions;
export default tableRedux.reducer;
