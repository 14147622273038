import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {List, ListItem, ListItemIcon, ListItemText, Collapse, Drawer as DrawerMUI, Box, Typography, Fade} from '@mui/material';
import {Link, useLocation} from "react-router-dom";
import {menuHandlerGetInfo} from "src/Handler/MenuHandler";
import {routingHandlerGetRouteByPathname} from "src/Handler/RoutingHandler";
import {color} from "src/Config/Theme";
import {RadioButtonUnchecked, Adjust, KeyboardArrowRight} from '@mui/icons-material';
import {drawerMainLayoutReduxClose, drawerMainLayoutReduxOpen} from "src/Redux/drawerMainLayoutRedux";
import {inArray} from "../../../Tool/ArrayTool";

const drawerOpenWidth = '260px';
const drawerCloseWidth = '68px';
const useStyles = {
    drawer: {
        transition: 'all 200ms',
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        overflowX: 'hidden',
        zIndex: 3,
        boxSizing: 'border-box',
        borderImage: 'conic-gradient(from var(--angle), ' + color.primaryOpacity + ', ' + color.primary + ' 0.1turn, ' + color.primary + ' 0.15turn, ' + color.primaryOpacity + ' 0.25turn) 30',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            background: color.backgroundPrimary,
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerShadow: {
        transition: 'all 200ms',
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        zIndex: 3,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            background: color.backgroundPrimary,
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        width: drawerOpenWidth
    },
    drawerClose: {
        width: drawerCloseWidth
    },
    drawerHeaderLock: {
        position: 'absolute',
        top: '29px',
        right: '13px',
        fontSize: '20px',
        color: color.textPrimary,
        cursor: 'pointer',
    },
    drawerListItem: {
        padding: '5px 4px !important',
        borderRadius: '5px',
        '&:hover': {
            background: color.primaryOpacityMore
        }
    },
    drawerListIcon: {
        paddingLeft: '10px',
        color: color.textPrimary,
        minWidth: '45px',
        '& .MuiSvgIcon-root': {
            fontSize: '20px'
        }
    },
    drawerListText: {
        cursor: 'pointer',
        color: color.textPrimary,
        '& .MuiListItemText-primary': {
            fontSize: '14px',
        }
    },
    drawerArrowIcon: {
        transition: 'all 200ms',
        color: color.textPrimary,
        minWidth: '25px',
        '& .MuiSvgIcon-root': {
            fontSize: '20px'
        }
    },
    drawerListOpen: {
        zIndex: 1,
        background: color.backgroundPrimary,
        boxSizing: "border-box",
        position: 'relative'
    },
    drawerIconClose: {
        zIndex: 2,
        position: 'absolute',
        padding: '12px',
        fontSize: '18px',
        cursor: 'pointer'
    }
};

function Drawer() {
    const dispatch = useDispatch();
    const location = useLocation();
    const drawerMainLayoutRedux = useSelector(state => state.drawerMainLayoutRedux);
    const screenRedux = useSelector(state => state.screenRedux);

    const [route, setRoute] = React.useState({});
    const [expanded, setExpanded] = React.useState('');
    const [expandedParent, setExpandedParent] = React.useState('');
    const [lock, setLock] = React.useState(drawerMainLayoutRedux.isOpen);
    const [showDrawerFull, setShowDrawerFull] = React.useState(false);
    const menuInfo = menuHandlerGetInfo();

    const list = () => {
        return (
            <List sx={{color: color.textPrimary, padding: 0}}>
                {
                    menuInfo.map((element, index) => {
                        if (element.children) { // list
                            return (
                                <Box key={index} sx={{padding: '2px 10px'}}>
                                    <Box sx={(drawerMainLayoutRedux.isOpen && expanded.startsWith(element.id)) ? useStyles.drawerListOpen : {}}>
                                        <ListItem
                                            key={element.id}
                                            onClick={() => setExpanded(element.id === expanded ? '' : element.id)}
                                            sx={{...useStyles.drawerListItem, ...((expanded.startsWith(element.id) || expandedParent === element.id) ? {background: color.primaryOpacity + ' !important'} : {})}}
                                        >
                                            <ListItemIcon sx={{...useStyles.drawerListIcon, ...((expandedParent === element.id) ? {color: color.textPrimaryActive} : {})}}>{element.icon}</ListItemIcon>
                                            <ListItemText sx={{...useStyles.drawerListText, ...((expandedParent === element.id) ? {color: color.textPrimaryActive} : {})}} primary={element.label}/>
                                            <ListItemIcon sx={useStyles.drawerArrowIcon}>
                                                <KeyboardArrowRight sx={{
                                                    ...{transition: 'all 200ms'},
                                                    ...((expanded.startsWith(element.id)) ? {transform: 'rotate(90deg)'} : {}),
                                                    ...((expandedParent === element.id) ? {color: color.textPrimaryActive} : {})
                                                }}/>
                                            </ListItemIcon>
                                        </ListItem>
                                        <Collapse in={(drawerMainLayoutRedux.isOpen && expanded.startsWith(element.id))} timeout="auto" unmountOnExit style={{zIndex: 0}}>
                                            {
                                                element.children.map((children, index) => {
                                                    return (
                                                        <Link key={index} to={children.path}>
                                                            <ListItem sx={{...useStyles.drawerListItem, ...{marginTop: '4px'}, ...((children.id === route.id || inArray(route.id, children.menuKeys)) ? {background: color.primary + ' !important'} : {})}}
                                                                      selected={(children.id === route.id || inArray(route.id, children.menuKeys))}
                                                                      button
                                                            >
                                                                <ListItemIcon sx={useStyles.drawerListIcon}/>
                                                                <ListItemText sx={{...useStyles.drawerListText, ...((children.id === route.id || inArray(route.id, children.menuKeys)) ? {color: color.textPrimaryActive + '!important'} : {})}} primary={children.label}/>
                                                            </ListItem>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                    </Box>
                                </Box>
                            );
                        }
                        else { // link
                            return (
                                <Box key={index} sx={{padding: '2px 10px'}}>
                                    <Link to={element.path} key={element.id}>
                                        <ListItem
                                            sx={{...useStyles.drawerListItem, ...((element.id === route.id) ? {backgroundColor: color.primary + '!important'} : {})}}
                                            selected={(element.id === route.id)}
                                            button
                                        >
                                            <ListItemIcon sx={{...useStyles.drawerListIcon, ...((element.id === route.id) ? {color: color.textPrimaryActive + '!important'} : {})}}>{element.icon}</ListItemIcon>
                                            <ListItemText sx={{...useStyles.drawerListText, ...((element.id === route.id) ? {color: color.textPrimaryActive + '!important'} : {})}} primary={element.label}/>
                                        </ListItem>
                                    </Link>
                                </Box>
                            );
                        }
                    })
                }
            </List>
        );
    };

    React.useEffect(() => {
        if (!showDrawerFull) dispatch(drawerMainLayoutReduxClose());
        let currentRoute = routingHandlerGetRouteByPathname(location.pathname);
        setRoute(currentRoute);

        let isFound = false;
        for (let index1 in menuInfo) {
            if (menuInfo[index1].children) {
                let childrens = menuInfo[index1].children;
                for (let index2 in childrens) {
                    if (childrens[index2].id === currentRoute.id || inArray(currentRoute.id, childrens[index2].menuKeys)) {
                        setExpandedParent(childrens[index2].parent);
                        isFound = true;
                        if (!expanded) {
                            setExpanded(childrens[index2].parent);
                        }
                    }
                }
            }
        }

        if (!isFound) setExpandedParent('');
    }, [location.pathname]);
    React.useEffect(() => {
        if (screenRedux.screen) {
            let isDrawerFull = (screenRedux.screen === 'MD' || screenRedux.screen === 'LG' || screenRedux.screen === 'XL');

            if (isDrawerFull && lock) {
                dispatch(drawerMainLayoutReduxOpen());
            }
            else if (isDrawerFull) {
                dispatch(drawerMainLayoutReduxClose());
            }

            if (!isDrawerFull) {
                dispatch(drawerMainLayoutReduxClose());
            }

            setShowDrawerFull(isDrawerFull);
        }
    }, [screenRedux.screen]);

    return <DrawerMUI
        className={showDrawerFull ? 'loader-bar' : ''}
        variant={showDrawerFull ? 'permanent' : 'temporary'}
        onClose={() => dispatch(drawerMainLayoutReduxClose())}
        sx={{
            ...(showDrawerFull ? useStyles.drawer : useStyles.drawerShadow),
            ...(drawerMainLayoutRedux.isOpen ? useStyles.drawerOpen : useStyles.drawerClose),
        }}
        open={drawerMainLayoutRedux.isOpen}
        onMouseEnter={() => dispatch(drawerMainLayoutReduxOpen())}
        onMouseLeave={() => {
            if (!lock && showDrawerFull) dispatch(drawerMainLayoutReduxClose());
        }}
    >
        <Box sx={{padding: '20px 18px'}}>
            <img width={35} height={35} src={'/images/logo.webp'} alt={'logo'}/>
            <Fade in={drawerMainLayoutRedux.isOpen} {...{timeout: drawerMainLayoutRedux.isOpen ? 500 : 0}}>
                <Typography component={'span'} sx={{color: color.textPrimary, padding: '20px', fontSize: '21px', lineHeight: '36px', verticalAlign: 'top'}}>Maker System</Typography>
            </Fade>
            {
                showDrawerFull &&
                <Fade in={drawerMainLayoutRedux.isOpen} {...{timeout: drawerMainLayoutRedux.isOpen ? 500 : 0}}>
                    {lock ? <Adjust onClick={() => setLock(false)} sx={useStyles.drawerHeaderLock}/> : <RadioButtonUnchecked onClick={() => setLock(true)} sx={useStyles.drawerHeaderLock}/>}
                </Fade>
            }
        </Box>
        {list()}
    </DrawerMUI>;
}


export default React.memo(Drawer);
