import {Dashboard, BrightnessAuto, VpnLock, Dvr, Support, Key, Dns, Psychology} from '@mui/icons-material';

export const menu = [
    {
        id: 'dashboard',
        label: 'Tableau de bord',
        icon: <Dashboard/>,
    },
    {
        id: 'softwares',
        label: 'Logiciels',
        icon: <Dvr/>,
    },
    {
        id: 'servers',
        label: 'Serveurs',
        icon: <Dns/>,
    },
    {
        id: 'passwords',
        label: 'Mots de passe',
        icon: <Key/>,
    },
    {
        id: 'vpn',
        label: 'VPN',
        icon: <VpnLock/>,
    },
    {
        id: 'support',
        label: 'Support',
        icon: <Support/>,
    },
    {
        id: 'documents',
        label: 'Documents',
        icon: <Support/>,
    },
    {
        id: 'administrator',
        label: 'Administrateur',
        icon: <BrightnessAuto/>,
        children: [
            {id: 'administrator_users', label: 'Utilisateurs'},
            {id: 'administrator_enterprises', label: 'Entreprises'},
            {id: 'administrator_servers', label: 'Serveurs'},
            {id: 'administrator_softwares', label: 'Logiciels'},
            {id: 'administrator_subscriptions', label: 'Abonnements'},
        ]
    }
];



