import React from 'react';
import {useDispatch} from "react-redux";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Typography} from "@mui/material";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import TableComponent from "src/Component/TableComponent";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import {color} from "src/Config/Theme";
import {Delete, ManageAccounts} from "@mui/icons-material";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import AutoCompleteComponent from "src/Component/AutoCompleteComponent";
import {tableReduxReload} from "src/Redux/tableRedux";

const useStyles = {
    avatar: {
        background: color.primaryOpacity,
        borderRadius: '50%',
        overflow: 'hidden',
        fontSize: '12px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: '2px',
        marginLeft: '25px',
    }
};

function EnterpriseOwnerListComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);

    // Redux
    const dispatch = useDispatch();

    // Tables
    const columns = [
        {
            name: "logo",
            label: " ",
            options: {filter: false, sort: false}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <ButtonIconComponent
                    background={color.colorDeleteBackground}
                    backgroundHover={color.colorDeleteBackgroundHover}
                    color={color.colorDelete}
                    icon={Delete}
                    onClick={() => {
                        routingApiHandlerFetch({
                            route: 'api_administrator_enterprise_owner',
                            method: 'DELETE',
                            params: {
                                enterprise: props.enterprise.uuid,
                                owner: row.uuid
                            },
                            data: handler.getDataApi(),
                            success: () => {
                                dispatch(snackbarReduxOpen({text: 'Responsable supprimé avec succès.', variant: 'success'}));
                                dispatch(tableReduxReload({id: 'api_administrator_enterprise_owners'}));
                            },
                            error: (response) => {
                                if (response.code === 400) {
                                    handler.setErrorApi(response.error);
                                }
                                else {
                                    dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                }
                            }
                        });
                    }}
                />
            </>
        );
    };

    // Form
    const [openDialog, setOpenDialog] = React.useState(false);
    const [form, setForm] = React.useState({
        owner: {
            id: 'owner',
            label: 'Responsable',
            helperText: 'Choisissez un responsable de l\'entreprise.',
            type: 'array',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const loadOwner = (text, callback) => {
        routingApiHandlerFetch({
            route: 'api_administrator_enterprise_owners_autocomplete',
            method: 'GET',
            data: {text: text},
            success: (response) => {
                callback(response.data);
            },
            error: (response) => {
                if (response.code === 400) {
                    handler.setErrorApi(response.error);
                }
                else {
                    dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                }
            }
        });
    };
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_administrator_enterprise_owner',
                method: 'POST',
                params: {
                    enterprise: props.enterprise.uuid,
                    owner: handler.getValue('owner')
                },
                success: () => {
                    handler.setLoading(false);
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Responsable enregistrée avec succès.', variant: 'success'}));
                    dispatch(tableReduxReload({id: 'api_administrator_enterprise_owners'}));
                    setOpenDialog(false);
                    handler.reset();
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box>
                <TableComponent
                    id={'api_administrator_enterprise_owners'}
                    title={'Responsables'}
                    icon={ManageAccounts}
                    columns={columns}
                    refreshInterval={5000}
                    promiseData={(resolve) => {
                        routingApiHandlerFetch({
                            route: 'api_administrator_enterprise_owners',
                            params: {enterprise: props.enterprise.uuid},
                            method: 'GET',
                            success: (response) => {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].actions = getAction(data[index]);
                                    data[index].logo = data[index].logo ? <img
                                        style={{marginTop: '2px', marginLeft: '25px', borderRadius: '25px'}}
                                        src={data[index].logo ? routingApiHandlerGetRoute({route: 'api_administrator_enterprise_logo', params: {user: data[index].uuid, logo: data[index].logo}}) : ''}
                                        alt={data.name}
                                        height={28}
                                        width={28}/> : <Box sx={useStyles.avatar}>
                                        <Typography sx={{fontSize: '12px'}}>{data[index].lastname[0] + data[index].firstname[0]}</Typography>
                                    </Box>;
                                }
                                resolve(data);
                            },
                            error: () => {
                                resolve([]);
                                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                            }
                        });
                    }}
                    actionFirst={{
                        label: 'Ajouter un responsable',
                        onClick: () => {
                            setOpenDialog(true);
                        }
                    }}
                />

                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle id="responsive-dialog-title" sx={{background: color.backgroundPrimary, color: color.textPrimary}}>
                        Ajouter un responsable
                    </DialogTitle>
                    <DialogContent sx={{background: color.backgroundPrimary, paddingTop: '5px !important', padding:'5px 24px'}}>
                        <AutoCompleteComponent id={'owner'} handler={handler} loadOptions={loadOwner}/>
                    </DialogContent>
                    <DialogActions sx={{background: color.backgroundPrimary}}>
                        <ButtonComponent onClick={() => setOpenDialog(false)} label={'Annuler'} color={color.textTertiary}/>
                        <ButtonComponent onClick={save} label={'Enregistrer'} loading={loading}/>
                    </DialogActions>
                </Dialog>
            </Box>
        </Fade>
    );
}

export default EnterpriseOwnerListComponent;
