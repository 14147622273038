import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Box, Fade, Grid, Typography} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Feed} from "@mui/icons-material";
import FileFieldComponent from "src/Component/FileFieldComponent";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";

function EnterpriseFormComponent(props) {
    // React
    const [logo, setLogo] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    // Router
    const navigate = useNavigate();
    const {enterprise} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Form
    const [form, setForm] = React.useState({
        name: {
            id: 'name',
            label: 'Nom',
            helperText: 'Entrez le nom de l\'entreprise.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        siret: {
            id: 'siret',
            label: 'Siret',
            helperText: 'Entrez le siret de l\'entreprise.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        address: {
            id: 'address',
            label: 'Adresse',
            helperText: 'Entrez l\'adresse de l\'entreprise.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        zipCode: {
            id: 'zipCode',
            label: 'Code postal',
            helperText: 'Entrez le code postal de l\'entreprise.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        city: {
            id: 'city',
            label: 'Ville',
            helperText: 'Entrez le ville de l\'entreprise.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        country: {
            id: 'country',
            label: 'Pays',
            helperText: 'Entrez le pays de l\'entreprise.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        countryCode: {
            id: 'countryCode',
            label: 'Code Pays',
            helperText: 'Entrez le code pays de l\'entreprise.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_administrator_enterprise',
                method: 'POST',
                params: {enterprise: enterprise ?? ''},
                data: handler.getDataApi(),
                success: (response) => {
                    handler.setLoading(false);
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Entreprise enregistrée avec succès.', variant: 'success'}))
                    navigate(routingHandlerGetRoutePathname('administrator_enterprise', {enterprise: response.data.enterprise}));
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    // Bar + Info
    React.useEffect(() => {
        if (props.enterprise) {
            handler.setDataApi(props.enterprise);
            setLogo(props.enterprise.logo);
        }
    }, [props.enterprise]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                        <Feed sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Information
                    </Typography>

                    <br/>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <FileFieldComponent
                                name={logo}
                                urlFile={logo ? routingApiHandlerGetRoute({route: 'api_administrator_enterprise_logo', params: {enterprise: enterprise, logo: logo}}) : ''}
                                urlUpload={routingApiHandlerGetRoute({route: 'api_administrator_enterprise_logo_upload', params: {enterprise: enterprise}})}
                                cantUpload={!!enterprise}
                                cantDownload={false}

                                onUploadSuccess={() => {
                                    routingApiHandlerFetch({
                                        route: 'api_administrator_enterprise',
                                        params: {enterprise: enterprise},
                                        method: 'GET',
                                        success: (response) => {
                                            setLogo(response.data.logo);
                                        }
                                    });
                                }}
                                onUploadFail={() => {
                                    dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                }}
                                accept="image/jpeg, image/png, image/webp"
                                sx={{height: '185px', width: '185px', maxWidth: '100%', borderRadius: '100px'}}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextFieldComponent handler={handler} id={'name'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextFieldComponent handler={handler} id={'siret'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextFieldComponent handler={handler} id={'address'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent handler={handler} id={'zipCode'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent handler={handler} id={'city'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent handler={handler} id={'country'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent handler={handler} id={'countryCode'} onSubmit={save}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <br/>

                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default EnterpriseFormComponent;
