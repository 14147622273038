import React from 'react';
import {useDispatch} from "react-redux";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import LoaderPageComponent from "src/Component/LoaderPageComponent";
import {Box, Grid, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {color} from "src/Config/Theme";
import {Feed, Apartment, Subtitles, LocationOn, Language} from "@mui/icons-material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";

function Enterprise() {
    // React
    const [loading, setLoading] = React.useState(true);
    const [enterpriseInfo, setEnterpriseInfo] = React.useState(null);

    // Redux
    const dispatch = useDispatch();

    // Router
    const {enterprise} = useParams();

    // Bar + Info
    React.useEffect(() => {
        setLoading(true);
        routingApiHandlerFetch({
            route: 'api_enterprise',
            params: {enterprise: enterprise},
            method: 'GET',
            success: (response) => {
                dispatch(barMainLayoutReduxChange({title: response.data.name}));
                setEnterpriseInfo(response.data);
                setTimeout(() => setLoading(false), 750);
            },
            error: () => {
                setTimeout(() => setLoading(false), 750);
            }
        });
    }, [enterprise]);

    return (
        loading ? <LoaderPageComponent/> : <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                            <Feed sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Information
                        </Typography>

                        <br/>

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <img
                                    src={enterpriseInfo.logo ? routingApiHandlerGetRoute({route: 'api_enterprise_logo', params: {enterprise: enterpriseInfo.uuid}}) : ''}
                                    alt="Logo"
                                    style={{height: '185px', width: '185px', maxWidth: '100%', borderRadius: '100px', margin: 'auto', display: 'block'}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <div>
                                        <Typography sx={{color: color.textSecondary, fontWeight: '500', marginBottom: '6px'}}>
                                            <Apartment sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Nom : {enterpriseInfo.name}
                                        </Typography>
                                        <Typography sx={{color: color.textSecondary, fontWeight: '500', marginBottom: '6px'}}>
                                            <Subtitles sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Siret : {enterpriseInfo.siret}
                                        </Typography>
                                        <Typography sx={{color: color.textSecondary, fontWeight: '500', marginBottom: '6px'}}>
                                            <LocationOn sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/>
                                            Adresse : {enterpriseInfo.address}, {enterpriseInfo.zipCode} {enterpriseInfo.city}
                                        </Typography>
                                        <Typography sx={{color: color.textSecondary, fontWeight: '500', marginBottom: '6px'}}>
                                            <Language sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/>
                                            Localisation : {enterpriseInfo.country}, {enterpriseInfo.countryCode}
                                        </Typography>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>

                        <br/>

                    </ShadowBoxComponent>
                </Grid>
            </Grid>
        </>
    );
}

export default Enterprise;
