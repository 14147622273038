import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import Store from 'src/Redux/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={Store.store}>
            <PersistGate loading={null} persistor={Store.persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
