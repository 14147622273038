import {createSlice} from '@reduxjs/toolkit';

const drawerMainLayoutRedux = createSlice({
    name: 'drawerMainLayoutRedux',
    initialState: {
        isOpen: true,
    },
    reducers: {
        drawerMainLayoutReduxOpen: (state, action) => {
            state.isOpen = true;
        },
        drawerMainLayoutReduxClose: (state, action) => {
            state.isOpen = false;
        },
    },
});

export const {drawerMainLayoutReduxOpen, drawerMainLayoutReduxClose} = drawerMainLayoutRedux.actions;
export default drawerMainLayoutRedux.reducer;
