import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Box, Fade, Grid, Typography} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Feed} from "@mui/icons-material";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import SelectComponent from "src/Component/SelectComponent";
import FileFieldComponent from "src/Component/FileFieldComponent";

function SoftwareFormComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [optionEnterprises, setOptionEnterprises] = React.useState([]);
    const [optionSubscriptions, setOptionSubscriptions] = React.useState([]);
    const [image, setImage] = React.useState('');
    const [optionServers, setOptionServers] = React.useState([]);

    // Router
    const navigate = useNavigate();
    const {software} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Form
    const [form, setForm] = React.useState({
        name: {
            id: 'name',
            label: 'Nom',
            helperText: 'Entrez le nom du logiciel.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        slug: {
            id: 'slug',
            label: 'Slug',
            helperText: 'Entrez le slug du logiciel.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        url: {
            id: 'url',
            label: 'Url',
            helperText: 'Entrez l\'url du logiciel.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        enterprise: {
            id: 'enterprise',
            label: 'Entreprise',
            helperText: 'Choisissez l\'entreprise du logiciel.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        subscription: {
            id: 'subscription',
            label: 'Abonnement',
            helperText: 'Choisissez l\'abonnement du logiciel.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        server: {
            id: 'server',
            label: 'Serveur',
            helperText: 'Choisissez le serveur du logiciel.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
    });
    const handler = formHandlerInit(form, setForm);
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);

            let d = handler.getDataApi();

            routingApiHandlerFetch({
                route: 'api_administrator_software',
                method: 'POST',
                params: {software: software ?? ''},
                data: {...d, ...{isDisabled: isDisabled}},
                success: (response) => {
                    handler.setLoading(false);
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Informations logiciel enregistrées avec succès.', variant: 'success'}));
                    props.setSoftware({...props.software, ...d, ...{isDisabled: isDisabled}});
                    navigate(routingHandlerGetRoutePathname('administrator_software', {software: response.data.software}));
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };
    const changeEnterprise = (value, subscription = '') => {
        if (value) {
            handler.setValue('subscription', subscription);
            routingApiHandlerFetch({
                route: 'api_administrator_software_enum_enterprise_subscriptions',
                params: {enterprise: value},
                method: 'GET',
                success: (response) => {
                    setOptionSubscriptions(response.data);
                },
                error: () => {
                    dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                }
            });
        }
    };

    // Bar + Info
    React.useEffect(() => {
        if (props.software) {
            handler.setDataApi(props.software);
            setImage(props.software.image);
            setIsDisabled(props.software.isDisabled);
        }
    }, [props.software]);
    React.useEffect(() => {
        routingApiHandlerFetch({
            route: 'api_administrator_software_enum_enterprises',
            method: 'GET',
            success: (response) => {
                setOptionEnterprises(response.data);
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
    }, []);
    React.useEffect(() => {
        if (props.software && props.software.enterprise) {
            routingApiHandlerFetch({
                route: 'api_administrator_software_enum_enterprises',
                params: {enterprise: props.software.enterprise},
                method: 'GET',
                success: (response) => {
                    setOptionEnterprises(response.data);
                },
                error: () => {
                    dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                }
            });
            changeEnterprise(props.software.enterprise, props.software.subscription);
        }
        routingApiHandlerFetch({
            route: 'api_administrator_software_enum_servers',
            method: 'GET',
            success: (response) => {
                setOptionServers(response.data);
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
    }, []);

    return (<>
            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{width: '100%', position: 'sticky', top: 0}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ShadowBoxComponent>
                                <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                                    <Feed sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Information
                                </Typography>

                                <div className="checkbox-wrapper-8" style={{position: 'absolute', top: '15px', right: '25px'}}>
                                    <input defaultChecked={!isDisabled} checked={!isDisabled} type="checkbox" className="tgl tgl-skewed"/>
                                    <label onClick={() => setIsDisabled(!isDisabled)} htmlFor="cb3-8" data-tg-on="ON" data-tg-off="OFF" className="tgl-btn"/>
                                </div>

                                <br/>

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <FileFieldComponent
                                            name={image}
                                            urlFile={image ? routingApiHandlerGetRoute({route: 'api_administrator_software_image', params: {software: software, image: image}}) : ''}
                                            urlUpload={routingApiHandlerGetRoute({route: 'api_administrator_software_image_upload', params: {software: software}})}
                                            cantUpload={!!software}
                                            cantDownload={false}

                                            onUploadSuccess={() => {
                                                routingApiHandlerFetch({
                                                    route: 'api_administrator_software',
                                                    params: {software: software},
                                                    method: 'GET',
                                                    success: (response) => {
                                                        setImage(response.data.image);
                                                    }
                                                });
                                            }}
                                            onUploadFail={() => {
                                                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                            }}
                                            accept="image/jpeg, image/png, image/webp"
                                            sx={{height: '185px', width: (image ? '280px' : '185px'), maxWidth: '100%', borderRadius: '100px', margin: '0 auto 20px'}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextFieldComponent handler={handler} id={'name'} onSubmit={save}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextFieldComponent handler={handler} id={'slug'} onSubmit={save}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <TextFieldComponent handler={handler} id={'url'} onSubmit={save}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <SelectComponent handler={handler} id={'server'} onSubmit={save} options={optionServers}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <SelectComponent handler={handler} id={'enterprise'} onSubmit={save} options={optionEnterprises} onChange={changeEnterprise}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <SelectComponent handler={handler} id={'subscription'} onSubmit={save} options={optionSubscriptions}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <br/>

                                <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                            </ShadowBoxComponent>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </>
    );
}

export default SoftwareFormComponent;
