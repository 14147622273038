import {cloneJson} from "src/Tool/CloneTool";
import moment from "moment";
import axios from 'axios';
import {routingApi, cacheConfig, host} from "src/Config/RoutingApi";
import {authenticationReduxLogout} from "../Redux/authenticationRedux";
import {dispatch} from "src/App";

let cache = {};
let removeTrailingSlash = (str) => {
    if (str.endsWith('/') && !str.endsWith('api/')) {
        return str.slice(0, -1);
    }
    return str;
}

export const routingApiHandlerGetRoute = (options) => {
    let route = routingApi[options.route];

    if (!route) {
        console.log('Option :');
        console.log(options.route);
        console.log('Route :');
        console.log(route);
        return null;
    }

    // Data
    if (options.data && options.method === 'GET') {
        route += '?';
        for (let index in options.data) {
            let dataGet = options.data[index] === null ? '' : options.data[index];
            dataGet = dataGet === undefined ? '' : dataGet;

            if (Array.isArray(dataGet)) {
                for (let index1 in dataGet) {
                    route += index + "[]=" + dataGet[index1] + "&";
                }
            }
            else {
                if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
            }
        }
        route = route.slice(0, -1);
    }

    // Params
    for (let key in options.params) {
        let regEx = new RegExp('{' + key + '}', 'gi');
        route = route.replace(regEx, options.params[key]);
    }

    return removeTrailingSlash(host + route);
};
export const routingApiHandlerFetch = function (options) {
    let route = routingApiHandlerGetRoute(options);
    if (!route) return;

    if (cache[route] && cache[route].age.isAfter(moment())) {
        if (options.success) {
            options.success(cloneJson(cache[route].data));
        }
    }
    else {
        const sc = (response) => {
            if (cacheConfig[options.route]) {
                if (options.route in cacheConfig) {
                    cache[route] = {
                        data: cloneJson(response.data),
                        age: moment().add(cacheConfig[options.route], 's'),
                    };
                    if (options.success) {
                        options.success(cloneJson(response.data));
                    }
                }
            }
            else {
                if (options.success) {
                    options.success(cloneJson(response.data));
                }
            }
        };

        if (options.method === 'POST') {
            axios.post(route, options.data, {withCredentials: true})
                .then(sc)
                .catch(err => {
                        if (err && err.response && err.response.status === 401) {
                            dispatch(authenticationReduxLogout());
                        }
                        else if (options.error) {
                            options.error(cloneJson(err.response.data));
                        }
                    }
                );
        }
        else if (options.method === 'DELETE') {
            axios.delete(route, options.data, {withCredentials: true})
                .then(sc)
                .catch(err => {
                        if (err && err.response && err.response.status === 401) {
                            dispatch(authenticationReduxLogout());
                        }
                        else if (options.error) {
                            options.error(cloneJson(err.response.data));
                        }
                    }
                );
        }
        else if (options.method === 'DOWNLOAD') {
            try {
                axios.get(route, {
                    responseType: 'blob',
                    withCredentials: true
                })
                    .then((response) => {
                        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', options.filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();

                        if (options.done) {
                            options.done();
                        }
                    })
                    .catch(err => {
                            if (err && err.response && err.response.status === 401) {
                                dispatch(authenticationReduxLogout());
                            }
                            else if (options.error) {
                                if (options.done) {
                                    options.done();
                                }
                            }
                        }
                    );
            } catch (error) {
                console.error("Error downloading the file:", error);
            }
        }
        else {
            axios.get(route, {withCredentials: true})
                .then(sc)
                .catch(err => {
                        if (err && err.response && err.response.status === 401) {
                            dispatch(authenticationReduxLogout());
                        }
                        else if (options.error) {
                            options.error(cloneJson(err.response.data));
                        }
                    }
                );
        }
    }
};

