import React from 'react';
import {CloudDownload, CloudUpload} from "@mui/icons-material";
import axios from "axios";
import {Box, CircularProgress} from "@mui/material";
import {color} from "src/Config/Theme";

let useStyles = {
    contentInput: {
        border: '1px #585858 dashed',
        margin: 'auto',
        textAlign: 'center',
        position: 'relative',
        boxSizing: 'border-box'
    }
};

function FileFieldComponent(props) {
    const inputRef = React.useRef();
    const [file, setFile] = React.useState(props.urlFile);
    const [loading, setLoading] = React.useState(false);

    const onChange = (e) => {
        let val = e.target.files[0];

        if (!val) {
            let v = (e.dataTransfer && e.dataTransfer.items) ? e.dataTransfer.items[0].getAsFile() : null;
            if (v && v.type === props.accept) val = v;
        }

        if (val) {
            setLoading(true);
            setFile('');

            let data = new FormData();
            data.append('file', val);

            axios.post(props.urlUpload, data)
                .then(function (response) {
                    if (response.status === 200) {
                        setTimeout(() => {
                            if (props.onUploadSuccess) props.onUploadSuccess();
                        }, 50);
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            if (props.onUploadFail) props.onUploadFail();
                        }, 50);
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                    }
                })
                .catch(err => {
                    setTimeout(() => {
                        if (props.onUploadFail) props.onUploadFail();
                    }, 50);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                });
        }
    };

    React.useEffect(() => {
        setFile(props.urlFile);
    }, [props.urlFile]);

    return (
        <Box sx={{...useStyles.contentInput, ...(props.sx ? props.sx : {}), ...(file || loading ? {border: 'none'} : {})}}>
            {
                loading && <CircularProgress size={'100%'} thickness={0.2} sx={{position: 'absolute', top: '0px', left: '0px', color: color.primary}}/>
            }

            {
                (props.cantUpload && !file) && <CloudUpload
                    sx={{
                        color: color.primary,
                        fontSize: 40,
                        cursor: 'pointer',
                        marginTop: 'calc(50% - 20px)'
                    }}/>
            }

            {
                (props.cantUpload) && <input
                    accept={props.accept}
                    style={{opacity: 0, width: '100%', height: '100%', position: 'absolute', left: 0, cursor: 'pointer'}}
                    type="file"
                    ref={inputRef}
                    onChange={onChange}
                    onDrop={onChange}/>
            }

            {
                file && <img src={file} style={{width: '100%', height: '100%', overflow: 'hidden'}} alt={props.alt}/>
            }

            {
                (props.cantDownload && file) && <CloudDownload
                    sx={{color: '#28a745', position: 'absolute', top: 10, right: 15, cursor: 'pointer'}}
                    onClick={() => {
                        fetch(props.urlFile, {method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer'})
                            .then(res => res.blob())
                            .then(res => {
                                const aElement = document.createElement('a');
                                aElement.setAttribute('download', props.name);
                                const href = URL.createObjectURL(res);
                                aElement.href = href;
                                aElement.setAttribute('target', '_blank');
                                aElement.click();
                                URL.revokeObjectURL(href);
                            });
                    }}/>
            }
        </Box>
    );
}

export default FileFieldComponent;
