import React from 'react';
import {Box} from '@mui/system';
import Drawer from "./Component/Drawer";
import AppBar from "./Component/AppBar";

export default function AuthenticationLayout(props) {
    return (
        <Box sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            margin: 0,
            padding: 0,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            overflow: 'hidden',
            boxSizing: 'border-box',
            display: 'flex'
        }}>
            {/* <== Drawer */}
            <Drawer/>
            {/* Drawer ==> */}

            <Box sx={{flexGrow: 1, boxSizing: 'border-box', overflowX: 'scroll', height: '100%', padding: '15px 0'}}>
                <Box sx={{maxWidth: '1400px', position: 'relative', margin: 'auto', padding: '0 15px'}}>
                    {/* <== AppBar */}
                    <AppBar/>
                    {/* AppBar ==> */}

                    {/* <== View */}
                    <props.view/>
                    {/* View ==> */}
                </Box>
            </Box>
        </Box>
    );
}
