import React from 'react';
import {Box} from "@mui/material";
import {color} from "src/Config/Theme";

const useStyles = {
    content: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        background: color.backgroundPrimary,
        margin: 0,
        padding: '12px',
        boxSizing: 'border-box',
        position: 'relative',
    }
};

function ShadowBoxComponent(props) {
    return (
        <Box sx={props.sx ? {...useStyles.content, ...props.sx} : {...useStyles.content}}>
            {props.children}
        </Box>
    );
}

export default ShadowBoxComponent;
