import React from 'react';
import {Box, Typography} from "@mui/material";
import {color} from "src/Config/Theme";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {styled} from "@mui/material/styles";
import {Link} from "react-router-dom";

const LinkStyled = styled(Link)(() => ({
    fontSize: '15px',
    textDecoration: 'none',
    color: '#7366F0'
}));

function CardComponent(props) {
    return (
        <ShadowBoxComponent sx={{padding: 0}}>
            <Box sx={{position: 'relative', paddingBottom: '30px'}}>
                <img src={'http://dev-app.maker-system.com/images/test-1.png'} alt="" style={{width: '100%'}}/>
                <Box sx={{
                    // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                    position: 'absolute',
                    height: '60px',
                    width: '60px',
                    background: color.backgroundPrimary,
                    borderRadius: '60px',
                    bottom: '0',
                    left: '20px',
                }}>
                    <img src={'http://dev-app.maker-system.com/images/sub-argent.webp'} alt="" style={{
                        width: '40px',
                        padding: '11px'
                    }}/>
                </Box>
            </Box>
            <Box sx={{padding: '0 15px 15px 15px'}}>

                <LinkStyled to={'https://www.larevuedupraticien.fr/'} target="_blank">
                    <Typography sx={{color: color.textSecondary, fontWeight: '600', fontSize: '16px'}}>
                        La revue du praticien
                    </Typography>
                </LinkStyled>

                <Typography sx={{color: color.textSecondary, fontWeight: '400', fontSize: '14px'}}>
                    Maker System
                </Typography>

                <Typography sx={{color: color.textSecondary, fontWeight: '400', fontSize: '12px'}}>
                    Silver
                </Typography>

                <br/>
                <ButtonComponent label={'Détails'}/>
            </Box>
        </ShadowBoxComponent>
    );
}

export default CardComponent;
