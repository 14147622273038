import React from 'react';
import {useDispatch} from "react-redux";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import LoaderPageComponent from "src/Component/LoaderPageComponent";
import MyProfileFormComponent from "./Component/MyProfileFormComponent";
import MyProfilePasswordComponent from "./Component/MyProfilePasswordComponent";
import {Grid} from "@mui/material";

function MyProfile() {
    // React
    const [loading, setLoading] = React.useState(true);
    const [userInfo, setUserInfo] = React.useState(null);

    // Redux
    const dispatch = useDispatch();

    // Bar + Info
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({title: 'Mon profil'}));

        routingApiHandlerFetch({
            route: 'api_my_profile',
            method: 'GET',
            success: (response) => {
                setUserInfo(response.data);
                setTimeout(() => setLoading(false), 750);
            },
            error: () => {
                setTimeout(() => setLoading(false), 750);
            }
        });
    }, []);

    return (
        loading ? <LoaderPageComponent/> : <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <MyProfileFormComponent user={userInfo}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <MyProfilePasswordComponent user={userInfo}/>
                </Grid>
            </Grid>
        </>
    );
}

export default MyProfile;
