import React from 'react';
import {Box} from "@mui/system";
import TableComponent from "src/Component/TableComponent";
import {Edit, PeopleOutline} from "@mui/icons-material";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import {color} from "src/Config/Theme";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import {Link} from "react-router-dom";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import {useDispatch} from "react-redux";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {Fade, Typography} from "@mui/material";

const useStyles = {
    avatar: {
        background: color.primaryOpacity,
        borderRadius: '50%',
        overflow: 'hidden',
        fontSize: '12px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: '2px',
        marginLeft: '25px',
    }
};

function Users() {
    // Redux
    const dispatch = useDispatch();

    // Tables
    const columns = [
        {
            name: "avatar",
            label: " ",
            options: {filter: false, sort: false}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Link to={routingHandlerGetRoutePathname('administrator_user', {user: row.uuid})}>
                    <ButtonIconComponent
                        background={color.colorEditBackground}
                        backgroundHover={color.colorEditBackgroundHover}
                        color={color.colorEdit}
                        icon={Edit}
                    />
                </Link>
            </>
        );
    };

    // Bar
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'Liste des utilisateurs'
        }));
    }, []);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box>
                <TableComponent
                    id={'api_administrator_users'}
                    title={'Utilisateurs'}
                    columns={columns}
                    height={'calc(100vh - 225px)'}
                    icon={PeopleOutline}
                    promiseData={(resolve) => {
                        routingApiHandlerFetch({
                            route: 'api_administrator_users',
                            method: 'GET',
                            success: (response) => {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].actions = getAction(data[index]);
                                    data[index].avatar = data[index].avatar ? <img
                                        style={{marginTop: '2px', marginLeft: '25px', borderRadius: '25px'}}
                                        src={data[index].avatar ? routingApiHandlerGetRoute({route: 'api_administrator_user_avatar', params: {user: data[index].uuid, avatar: data[index].avatar}}) : ''}
                                        alt={data.name}
                                        height={28}
                                        width={28}/> : <Box sx={useStyles.avatar}>
                                        <Typography sx={{fontSize: '12px'}}>{data[index].firstname[0] + data[index].lastname[0]}</Typography>
                                    </Box>;
                                }
                                resolve(data);
                            },
                            error: () => {
                                resolve([]);
                            }
                        });
                    }}
                    actionFirst={{
                        label: 'Ajouter un utilisateur',
                        link: routingHandlerGetRoutePathname('administrator_user', {user: null})
                    }}
                />
            </Box>
        </Fade>
    );
}

export default Users;

