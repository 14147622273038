import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducer
import authenticationRedux from './authenticationRedux';
import screenRedux from './screenRedux';
import drawerMainLayoutRedux from './drawerMainLayoutRedux';
import tableRedux from './tableRedux';
import barMainLayoutRedux from './barMainLayoutRedux';
import snackbarRedux from './snackbarRedux';

const rootReducer = combineReducers({
    authenticationRedux: authenticationRedux,
    screenRedux: screenRedux,
    drawerMainLayoutRedux: drawerMainLayoutRedux,
    tableRedux: tableRedux,
    barMainLayoutRedux: barMainLayoutRedux,
    snackbarRedux: snackbarRedux,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['screenRedux', 'tableRedux', 'barMainLayoutRedux', 'snackbarRedux'],
    whitelist: ['authenticationRedux', 'drawerMainLayoutRedux']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
    }),
});
const persistor = persistStore(store);

export default {store, persistor};
