import {createSlice} from '@reduxjs/toolkit';

const screenRedux = createSlice({
    name: 'screenRedux',
    initialState: {
        screen: null,
        size: null,
        landscape: null,
    },
    reducers: {
        screenReduxChange: (state, action) => {
            state.screen = action.payload.screen;
            state.size = action.payload.size;
            state.landscape = action.payload.landscape;
        }
    },
});

export const {screenReduxChange} = screenRedux.actions;
export default screenRedux.reducer;
