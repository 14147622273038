import React from 'react';
import {Box} from "@mui/system";
import {useDispatch} from "react-redux";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {Grid} from "@mui/material";
import CardComponent from "./Component/CardComponent";

function Softwares() {
    // Redux
    const dispatch = useDispatch();

    // Nav
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'Logiciels',
            canBack: false
        }));
    }, []);

    return (
        <Box sx={{width: '100%'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} sx={{position: 'relative'}}>
                    <CardComponent/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} sx={{position: 'relative'}}>
                    <CardComponent/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} sx={{position: 'relative'}}>
                    <CardComponent/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} sx={{position: 'relative'}}>
                    <CardComponent/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Softwares;
