export const roles = [
    {value: 'ROLE_USER', label: 'Rôle par défaut'},
    {value: 'ROLE_VPN', label: 'VPN'},
    {value: 'ROLE_SUPPORT', label: 'Support'},
    {value: 'ROLE_PASSWORDS', label: 'Mots de passe'},
    {value: 'ROLE_ADMINISTRATOR_USER', label: 'Admin - Gestion des utilisateur'},
    {value: 'ROLE_ADMINISTRATOR_ENTERPRISE', label: 'Admin - Gestion des entreprise'},
    {value: 'ROLE_ADMINISTRATOR_SERVER', label: 'Admin - Gestion des serveurs'},
    {value: 'ROLE_ADMINISTRATOR_SOFTWARE', label: 'Admin - Gestion des logiciels'},
    {value: 'ROLE_ADMINISTRATOR_SUBSCRIPTION', label: 'Admin - Gestion des abonnements'},
];

