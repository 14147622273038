import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Box, Fade, Grid, Typography} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Feed, Euro, Percent} from "@mui/icons-material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import SelectComponent from "src/Component/SelectComponent";

function SubscriptionFormComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [optionEnterprises, setOptionEnterprises] = React.useState([]);
    const [optionTypes, setOptionTypes] = React.useState([]);

    // Router
    const navigate = useNavigate();
    const {subscription} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Form
    const [form, setForm] = React.useState({
        label: {
            id: 'label',
            label: 'Libellé',
            helperText: 'Entrez le libellé de l\'abonnement.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        subscriptionType: {
            id: 'subscriptionType',
            label: 'Type',
            helperText: 'Choisissez le type l\'abonnement.',
            type: 'integer',
            value: '',
            options: {validation: ['required']}
        },
        enterprise: {
            id: 'enterprise',
            label: 'Entreprise',
            helperText: 'Choisissez l\'entreprise de l\'abonnement.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        priceHT: {
            id: 'priceHT',
            label: 'Prix HT',
            helperText: 'Entrez le prix HT de l\'abonnement.',
            type: 'float',
            value: '',
            options: {validation: ['required']}
        },
        priceTTC: {
            id: 'priceTTC',
            label: 'Prix TTC',
            helperText: 'Entrez le prix TTC de l\'abonnement.',
            type: 'float',
            value: '',
            options: {validation: ['required']}
        },
        TVA: {
            id: 'TVA',
            label: 'TVA',
            helperText: 'Entrez la TVA de l\'abonnement.',
            type: 'float',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_administrator_subscription',
                method: 'POST',
                params: {subscription: subscription ?? ''},
                data: handler.getDataApi(),
                success: (response) => {
                    handler.setLoading(false);
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Utilisateur enregistré avec succès.', variant: 'success'}))
                    navigate(routingHandlerGetRoutePathname('administrator_subscription', {subscription: response.data.subscription}));
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    // Bar + Info
    React.useEffect(() => {
        if (props.subscription) {
            handler.setDataApi(props.subscription);
        }
    }, [props.subscription]);
    React.useEffect(() => {
        routingApiHandlerFetch({
            route: 'api_administrator_subscription_enum_enterprises',
            method: 'GET',
            success: (response) => {
                setOptionEnterprises(response.data);
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
        routingApiHandlerFetch({
            route: 'api_administrator_subscription_enum_subscription_types',
            method: 'GET',
            success: (response) => {
                setOptionTypes(response.data);
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
    }, []);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                        <Feed sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Information
                    </Typography>

                    <br/>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TextFieldComponent handler={handler} id={'label'} onSubmit={save}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <SelectComponent handler={handler} id={'subscriptionType'} onSubmit={save} options={optionTypes}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <SelectComponent handler={handler} id={'enterprise'} onSubmit={save} options={optionEnterprises}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TextFieldComponent handler={handler} id={'TVA'} onSubmit={save} adornment={<Percent sx={{color: color.textSecondary, fontSize: '18px'}}/>}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TextFieldComponent handler={handler} id={'priceHT'} onSubmit={save} adornment={<Euro sx={{color: color.textSecondary, fontSize: '18px'}}/>}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TextFieldComponent handler={handler} id={'priceTTC'} onSubmit={save} adornment={<Euro sx={{color: color.textSecondary, fontSize: '18px'}}/>}/>
                        </Grid>
                    </Grid>

                    <br/>

                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default SubscriptionFormComponent;
