import React from 'react';
import {Box} from "@mui/system";
import {useDispatch} from "react-redux";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {Grid} from "@mui/material";
import {color} from "../../Config/Theme";

function Dashboard() {
    // Redux
    const dispatch = useDispatch();

    // Nav
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'Tableau de bord',
            canBack: false
        }));
    }, []);

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ShadowBoxComponent sx={{background: color.primary, color: color.textPrimaryActive}}>
                        Tableau de bord
                    </ShadowBoxComponent>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Dashboard;
