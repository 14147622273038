import React from 'react';
import {Box, Fade} from "@mui/material";
import TabsComponent from "src/Component/TabsComponent";
import ServerFormComponent from "./ServerFormComponent";
import {Dvr, CallSplit, Security, VpnLock, Code} from "@mui/icons-material";
import ServerFirewallComponent from "./ServerFirewallComponent";
import ServerReverseProxyComponent from "./ServerReverseProxyComponent";

function ServerTabsComponent(props) {
    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%', height: '100%'}}>
                <TabsComponent
                    tabDefault={'information'}
                    tabs={[
                        {
                            key: 'information', label: <span>
                                <Dvr sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                Général
                        </span>
                        },
                        {
                            key: 'code', label: <span>
                                <Code sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                SSH
                        </span>
                        },
                        {
                            key: 'firewall', label: <span>
                                <Security sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                Firewall
                        </span>
                        },
                        {
                            key: 'reverse-proxy', label: <span>
                                <CallSplit sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                Reverse Proxy
                        </span>
                        },
                        {
                            key: 'vpn', label: <span>
                                <VpnLock sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                VPN
                        </span>
                        }
                    ]}
                >
                    <Box key={'information'} sx={{width: '100%', height: '100%'}}>
                        <ServerFormComponent server={props.server}/>
                    </Box>
                    <Box key={'code'} sx={{width: '100%', height: '100%'}}>
                    </Box>
                    <Box key={'firewall'} sx={{width: '100%', height: '100%'}}>
                        <ServerFirewallComponent server={props.server}/>
                    </Box>
                    <Box key={'reverse-proxy'} sx={{width: '100%', height: '100%'}}>
                        <ServerReverseProxyComponent server={props.server}/>
                    </Box>
                    <Box key={'vpn'} sx={{width: '100%', height: '100%'}}>
                    </Box>
                </TabsComponent>
            </Box>
        </Fade>
    );
}

export default ServerTabsComponent;
