import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Snackbar} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import {snackbarReduxClose} from "src/Redux/snackbarRedux";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarHigher() {
    const snackbarRedux = useSelector(state => state.snackbarRedux);
    const dispatch = useDispatch();

    const close = () => dispatch(snackbarReduxClose());

    return (
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={snackbarRedux.isOpen}
            autoHideDuration={snackbarRedux.duration}
            onClose={close}
            key={'bottom' + 'right'}
        >
            <Alert onClose={close} severity={snackbarRedux.variant} sx={{width: '100%'}}>
                {snackbarRedux.text}
            </Alert>
        </Snackbar>
    );
}
