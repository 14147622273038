import React from 'react';
import {useDispatch} from "react-redux";
import {screenReduxChange} from "src/Redux/screenRedux";

function ScreenHigher() {
    const dispatch = useDispatch();

    const resize = () => {
        let screen = '';

        if (window.innerWidth < 600) screen = 'XS';
        if (window.innerWidth >= 600 && window.innerWidth < 960) screen = 'SM';
        if (window.innerWidth >= 960 && window.innerWidth < 1280) screen = 'MD';
        if (window.innerWidth >= 1280 && window.innerWidth < 1920) screen = 'LG';
        if (window.innerWidth >= 1920) screen = 'XL';

        dispatch(screenReduxChange({
            screen: screen,
            size: window.innerWidth,
            landscape: window.screen.orientation && window.screen.orientation.angle === 90
        }));
    };

    React.useEffect(() => {
        window.removeEventListener('resize', resize)
        window.addEventListener('resize', resize);
        window.addEventListener('orientationchange', resize);
        resize();
    }, []);
}

export default React.memo(ScreenHigher);
