import React from 'react';
import {useDispatch} from "react-redux";
import {Box, Fade, Grid, Typography} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Feed} from "@mui/icons-material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";

function MyProfilePasswordComponent() {
    // React
    const [loading, setLoading] = React.useState(false);

    // Redux
    const dispatch = useDispatch();

    // Form
    const [form, setForm] = React.useState({
        passwordCurrent: {
            id: 'passwordCurrent',
            label: 'Mot de passe actuel',
            helperText: 'Entrez votre mot de passe actuel.',
            type: 'password',
            value: '',
            options: {validation: ['required']}
        },
        password: {
            id: 'password',
            label: 'Mot de passe',
            helperText: 'Entrez votre mot de passe.',
            type: 'password',
            value: '',
            options: {validation: ['required', 'password-secure']}
        },
        passwordRepeat: {
            id: 'passwordRepeat',
            label: 'Confirmation',
            helperText: 'Entrez la confirmation de votre mot de passe.',
            type: 'password',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_my_profile_password',
                method: 'POST',
                data: handler.getDataApi(),
                success: () => {
                    handler.setLoading(false);
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Mot de passe enregistré avec succès.', variant: 'success'}))
                    handler.reset();
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                        <Feed sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Mot de passe
                    </Typography>

                    <br/>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent handler={handler} id={'passwordCurrent'} onSubmit={save}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent handler={handler} id={'password'} onSubmit={save}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent handler={handler} id={'passwordRepeat'} onSubmit={save}/>
                        </Grid>
                    </Grid>

                    <br/>

                    <ButtonComponent label={'Changer de mot de passe'} onClick={save} loading={loading}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default MyProfilePasswordComponent;
