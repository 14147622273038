import {createSlice} from '@reduxjs/toolkit';

const barMainLayoutRedux = createSlice({
    name: 'barMainLayoutRedux',
    initialState: {
        title: '',
        urlBack: false
    },
    reducers: {
        barMainLayoutReduxChange: (state, action) => {
            state.title = action.payload.title;
            state.urlBack = action.payload.urlBack
        }
    },
});

export const {barMainLayoutReduxChange} = barMainLayoutRedux.actions;
export default barMainLayoutRedux.reducer;
