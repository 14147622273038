import {createSlice} from '@reduxjs/toolkit';

const snackbarRedux = createSlice({
    name: 'snackbarRedux',
    initialState: {
        isOpen: false,
        text: null,
        variant: null,
        duration: 3000
    },
    reducers: {
        snackbarReduxOpen: (state, action) => {
            state.isOpen = true;
            state.text = action.payload.text;
            state.variant = action.payload.variant;
            state.duration = action.payload.duration ?? 3000;
        },
        snackbarReduxClose: (state) => {
            state.isOpen = false;
            state.duration = 3000;
        },
    },
});

export const {snackbarReduxOpen, snackbarReduxClose} = snackbarRedux.actions;
export default snackbarRedux.reducer;
