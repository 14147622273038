import React from "react";
import {routingHandlerSecurityGetRoutes} from "./RoutingHandler";
import {menu} from "src/Config/Menu";

export const menuHandlerGetInfo = () => {
    let routes = routingHandlerSecurityGetRoutes();
    let menuTmp = [];

    for (let index1 in menu) {
        let children = [];

        for (let index2 in menu[index1].children) {
            if (routes[menu[index1].children[index2].id]) {
                let c = menu[index1].children[index2];
                c.path = routes[menu[index1].children[index2].id].path

                if (c.params) {
                    for (let key in c.params) {
                        if (c.params[key]) {
                            let regEx = new RegExp(':' + key + '[?]?', 'gi');
                            c.path = c.path.replace(regEx, c.params[key]);
                        }
                        else {
                            let regEx = new RegExp('/:' + key + '[?]?', 'gi');
                            c.path = c.path.replace(regEx, '');
                        }

                    }
                }

                c.parent = menu[index1].id;
                c.menuKeys = routes[menu[index1].children[index2].id].menuKeys ?? [];
                children.push(c);
            }
        }

        if (children.length > 0) {
            let m = {
                id: menu[index1].id,
                label: menu[index1].label,
                icon: menu[index1].icon,
            };
            m.children = children;
            menuTmp.push(m);
        } else {
            let c = menu[index1];
            if(routes[c.id]) {
                c.path = routes[c.id].path

                if (c.params) {
                    for (let key in c.params) {
                        if (c.params[key]) {
                            let regEx = new RegExp(':' + key + '[?]?', 'gi');
                            c.path = c.path.replace(regEx, c.params[key]);
                        }
                        else {
                            let regEx = new RegExp('/:' + key + '[?]?', 'gi');
                            c.path = c.path.replace(regEx, '');
                        }
                    }
                }

                let m = {
                    id: c.id,
                    label: c.label,
                    icon: c.icon,
                    path: c.path,
                };
                menuTmp.push(m);
            }
        }
    }

    return menuTmp;
};



