import React from 'react';
import {LoadingButton} from '@mui/lab';
import {color} from "src/Config/Theme";

function ButtonComponent(props) {
    return (
        <LoadingButton
            loading={props.loading}
            disabled={props.disabled}
            variant={props.outlined ? 'outlined' : 'contained'}
            sx={
                {
                    width: '100%',
                    '& .MuiCircularProgress-root': {
                        color: color.primary
                    },
                    ...(
                        props.color ? {
                            backgroundColor: props.outlined ? 'unset' : props.color,
                            color: props.loading ? color.textPrimaryActive : (props.outlined ? props.color : color.textPrimaryActive),
                            '&:hover': {
                                backgroundColor: props.outlined ? 'unset' : props.colorHover + ' !important',
                            }
                        } : {
                            backgroundColor: props.outlined ? 'unset' : color.primary,
                            color: props.loading ? color.textPrimaryActive : (props.outlined ? props.primary : color.textPrimaryActive),
                            '&:hover': {
                                backgroundColor: props.outlined ? 'unset' : color.primaryHover + ' !important',
                            }
                        }
                    ),
                    ...props.sx
                }
            }
            onClick={props.onClick}>
            {props.label}
        </LoadingButton>
    );
}

export default ButtonComponent;
