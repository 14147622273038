import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import LoaderPageComponent from "src/Component/LoaderPageComponent";
import UserFormComponent from "./Component/UserFormComponent";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";

function User() {
    // React
    const [loading, setLoading] = React.useState(true);
    const [userInfo, setUserInfo] = React.useState(null);

    // Router
    const {user} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Bar + Info
    React.useEffect(() => {
        if (user) {
            dispatch(barMainLayoutReduxChange({title: 'Éditer un utilisateur', urlBack: routingHandlerGetRoutePathname('administrator_users')}));

            routingApiHandlerFetch({
                route: 'api_administrator_user',
                params: {user: user},
                method: 'GET',
                success: (response) => {
                    setUserInfo(response.data);
                    setTimeout(() => setLoading(false), 750);
                },
                error: (response) => {
                    setTimeout(() => setLoading(false), 750);
                }
            });
        }
        else {
            dispatch(barMainLayoutReduxChange({title: 'Ajouter un utilisateur', urlBack: routingHandlerGetRoutePathname('administrator_users')}));
            setLoading(false);
        }
    }, [user]);

    return (
        loading ? <LoaderPageComponent/> :<>
            <UserFormComponent user={userInfo}/>
        </>
    );
}

export default User;
