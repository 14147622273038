import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import LoaderPageComponent from "src/Component/LoaderPageComponent";
import SubscriptionFormComponent from "./Component/SubscriptionFormComponent";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";

function Subscription() {
    // React
    const [loading, setLoading] = React.useState(true);
    const [subscriptionInfo, setSubscriptionInfo] = React.useState(null);

    // Router
    const {subscription} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Bar + Info
    React.useEffect(() => {
        if (subscription) {
            dispatch(barMainLayoutReduxChange({title: 'Éditer un abonnement', urlBack: routingHandlerGetRoutePathname('administrator_subscriptions')}));

            routingApiHandlerFetch({
                route: 'api_administrator_subscription',
                params: {subscription: subscription},
                method: 'GET',
                success: (response) => {
                    setSubscriptionInfo(response.data);
                    setTimeout(() => setLoading(false), 750);
                },
                error: (response) => {
                    setTimeout(() => setLoading(false), 750);
                }
            });
        }
        else {
            dispatch(barMainLayoutReduxChange({title: 'Ajouter un abonnement', urlBack: routingHandlerGetRoutePathname('administrator_subscriptions')}));
            setLoading(false);
        }
    }, [subscription]);

    return (
        loading ? <LoaderPageComponent/> :<>
            <SubscriptionFormComponent subscription={subscriptionInfo}/>
        </>
    );
}

export default Subscription;
