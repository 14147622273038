import React from 'react';
import ReactApexChart from "react-apexcharts";
import {Box, Fade} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {routingApiHandlerFetch} from "../../../../Handler/RoutingApiHandler";

function ServerStatRAMComponent(props) {
    // React
    const [series, setSeries] = React.useState([
        {
            name: 'Buffers',
            data: [],
        },
        {
            name: 'Cache',
            data: [],
        },
        {
            name: 'Utilisée',
            data: [],
        },
        {
            name: 'Libre',
            data: []
        }
    ]);
    const options = {
        chart: {
            foreColor: "#fff",
            type: 'area',
            stacked: true,
            toolbar: {
                show: false
            }
        },
        title: {
            text: 'RAM',
            align: 'left',
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 1
        },
        colors: ["#fc8631", "#1779ea", "#f02f62", "#34b618"],
        grid: {
            borderColor: "#40475D"
        },
        fill: {
            type: "gradient",
            gradient: {
                gradientToColors: ["#F55555", "#6078ea", "#6094ea"]
            }
        },
        yaxis: {
            min: 0,
            tickAmount: 10,
            opposite: true,
            labels: {
                offsetX: -10,
                formatter: function (value) {
                    return Math.floor(value) + " Mo";
                }
            },
        },
        xaxis: {
            type: 'datetime',
            axisTicks: {
                color: "#333"
            },
            axisBorder: {
                color: "#333"
            }
        },
        tooltip: {
            theme: "dark",
            x: {
                format: 'dd/MM/yy HH:mm:ss'

            }
        },
    };

    React.useEffect(() => {
        if (props.server) {
            routingApiHandlerFetch({
                route: 'api_administrator_server_log_ram',
                params: {server: props.server.uuid},
                method: 'GET',
                success: (response) => {
                    setSeries([
                        {
                            name: 'Buffers',
                            data: response.data.buffers,
                        },
                        {
                            name: 'Cache',
                            data: response.data.cached,
                        },
                        {
                            name: 'Utilisée',
                            data: response.data.used,
                        },
                        {
                            name: 'Libre',
                            data: response.data.free,
                        }
                    ]);
                },
                error: () => {
                }
            });

            const loopInterval = setInterval(() => {
                routingApiHandlerFetch({
                    route: 'api_administrator_server_log_ram',
                    params: {server: props.server.uuid},
                    method: 'GET',
                    success: (response) => {
                        setSeries([
                            {
                                name: 'Buffers',
                                data: response.data.buffers,
                            },
                            {
                                name: 'Cache',
                                data: response.data.cached,
                            },
                            {
                                name: 'Utilisée',
                                data: response.data.used,
                            },
                            {
                                name: 'Libre',
                                data: response.data.free,
                            }
                        ]);
                    },
                    error: () => {
                    }
                });
            }, 1000 * 60);

            return () => {
                clearInterval(loopInterval);
            };
        }
    }, [props.server]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <ReactApexChart options={options} series={series} type={'area'} height={'300px'}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default ServerStatRAMComponent;
