import React from 'react';
import {Box, CircularProgress, Fade} from "@mui/material";
import ShadowBoxComponent from "./ShadowBoxComponent";
import {useSelector} from "react-redux";
import {color} from "../Config/Theme";

const useStyles = {
    loader: {
        textAlign: 'center',
        width: '100%',
        height: 'calc(100vh - 110px)',
        display: 'flex'
    },
    loaderContent: {
        width: 300,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

function LoaderPageComponent() {
    const screenReducer = useSelector(state => state.screenRedux);
    const [isSmallView, setSmallView] = React.useState(false);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <Fade in={true} {...{timeout: 1000}}>
            <Box sx={useStyles.loader}>
                <Box sx={useStyles.loaderContent} style={isSmallView ? {width: 225} : {}}>
                    <Fade in={true} {...{timeout: 500}}>
                        <Box sx={{
                            position: 'absolute',
                            top: 'calc(50% - 30px)',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center'
                        }}>
                            <img width={50} height={50} src={'/images/logo.webp'} alt={'logo'}/><br/>
                            <CircularProgress size={'70px'} thickness={1} sx={{position: 'absolute', top: '-10px', left: '-10px', color: color.primary}}/>
                        </Box>
                    </Fade>
                </Box>
            </Box>
        </Fade>
    );
}

export default LoaderPageComponent;
