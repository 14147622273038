import React from 'react';
import {Box} from "@mui/system";
import TableComponent from "src/Component/TableComponent";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import {color} from "src/Config/Theme";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {useDispatch} from "react-redux";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {Dvr, Edit, CheckCircleOutline, RemoveCircleOutline, NotInterested, ErrorOutline, PowerSettingsNew} from "@mui/icons-material";
import ButtonIconComponent from "src/Component/ButtonIconComponent";

const LinkStyled = styled(Link)(() => ({
    textDecoration: 'none',
    color: color.textPrimary
}));

function Softwares() {
    // React
    const dispatch = useDispatch();

    // Tables
    const customSort = (order) => {
        return (obj1, obj2) => {
            const val1 = obj1.data.props.sort;
            const val2 = obj2.data.props.sort;

            if (val1 < val2) {
                return order === 'asc' ? -1 : 1;
            }
            if (val1 > val2) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        };
    };
    const columns = [
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "serverName",
            label: "Serveur",
            options: {filter: true, sort: true}
        },
        {
            name: "slug",
            label: "Slug",
            options: {filter: true, sort: true}
        },
        {
            name: "url",
            label: "URL",
            options: {filter: true, sort: true}
        },
        {
            name: "backupDatabaseAutomatic",
            label: "Backup Base de données",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "backupVolumeAutomatic",
            label: "Backup Volume",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "power",
            label: "Power",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Link to={routingHandlerGetRoutePathname('administrator_software', {software: row.uuid})}>
                    <ButtonIconComponent
                        background={color.colorEditBackground}
                        backgroundHover={color.colorEditBackgroundHover}
                        color={color.colorEdit}
                        icon={Edit}
                    />
                </Link>
            </>
        );
    };

    // Bar
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'Liste des logiciels',
            canBack: false
        }));
    }, []);

    return (
        <Box>
            <TableComponent
                id={'api_administrator_softwares'}
                title={'Logiciels'}
                columns={columns}
                height={'calc(100vh - 225px)'}
                refreshInterval={5000}
                icon={Dvr}
                promiseData={(resolve) => {
                    routingApiHandlerFetch({
                        route: 'api_administrator_softwares',
                        method: 'GET',
                        success: (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].actions = getAction(data[index]);

                                if (data[index].isDisabled) {
                                    data[index]['backupDatabaseAutomatic'] = <NotInterested sort={'0'} sx={{color: color.textDisabled + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                    data[index]['backupVolumeAutomatic'] = <NotInterested sort={'0'} sx={{color: color.textDisabled + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                    data[index]['power'] = <PowerSettingsNew sort={'2'} sx={{color: color.textDisabled + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                }
                                else {
                                    if(data[index]['isDown']) {
                                        data[index]['power'] = <PowerSettingsNew sort={'0'} sx={{color: color.colorError + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                    } else {
                                        data[index]['power'] = <PowerSettingsNew sort={'1'} sx={{color: color.colorSuccess + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                    }

                                    if (data[index]['isBackupDatabaseError']) { // En Erreur
                                        data[index]['backupDatabaseAutomatic'] = <RemoveCircleOutline sort={'1'} sx={{color: color.colorError + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                    }
                                    else if (!data[index]['hasBackupDatabaseAutomatic']) { // Non Configuré
                                        data[index]['backupDatabaseAutomatic'] = <NotInterested sort={'0'} sx={{color: color.textDisabled + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                    }
                                    else {
                                        if (data[index]['isBackupDatabaseLate']) { // En retard
                                            data[index]['backupDatabaseAutomatic'] = <ErrorOutline sort={'3'} sx={{color: color.colorWarning + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                        }
                                        else { // Succès
                                            data[index]['backupDatabaseAutomatic'] = <CheckCircleOutline sort={'2'} sx={{color: color.colorSuccess + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                        }
                                    }

                                    if (data[index]['isBackupVolumeError']) { // En Erreur
                                        data[index]['backupVolumeAutomatic'] = <RemoveCircleOutline sort={'1'} sx={{color: color.colorError + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                    }
                                    else if (!data[index]['hasBackupVolumeAutomatic']) { // Non Configuré
                                        data[index]['backupVolumeAutomatic'] = <NotInterested sort={'0'} sx={{color: color.textDisabled + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                    }
                                    else {
                                        if (data[index]['isBackupVolumeLate']) { // En retard
                                            data[index]['backupVolumeAutomatic'] = <ErrorOutline sort={'3'} sx={{color: color.colorWarning + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                        }
                                        else { // Succès
                                            data[index]['backupVolumeAutomatic'] = <CheckCircleOutline sort={'2'} sx={{color: color.colorSuccess + ' !important', fontSize: '20px', padding: '6px 4px 1px'}}/>;
                                        }
                                    }
                                }

                                data[index]['url'] = <LinkStyled target="_blank" to={data[index]['url']}>{data[index]['url']}</LinkStyled>
                            }
                            resolve(data);
                        },
                        error: () => {
                            resolve([]);
                        }
                    });
                }}
                actionFirst={{
                    label: 'Ajouter un logiciel',
                    link: routingHandlerGetRoutePathname('administrator_software', {software: null})
                }}
            />
        </Box>
    );
}

export default Softwares;
