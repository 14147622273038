import React from 'react';
import {Box} from '@mui/system';
import {color} from 'src/Config/Theme';

export default function MainLayout(props) {
    return (
        <Box sx={{
            backgroundColor: color.backgroundPrimary,
            position: 'absolute',
            height: '100%',
            width: '100%',
            margin: 0,
            padding: 0,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            overflow: 'hidden',
            boxSizing: 'border-box'
        }}>
            <props.view/>
        </Box>
    );
}
