import React from 'react';
import {Box, Chip, Fade} from "@mui/material";
import {CloudSync, BrowserUpdated, Restore} from "@mui/icons-material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import TableComponent from "src/Component/TableComponent";
import {useParams} from "react-router-dom";
import moment from "moment";
import {color} from "src/Config/Theme";
import {tableReduxReload} from "src/Redux/tableRedux";
import {useDispatch} from "react-redux";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import download from 'react-useanimations/lib/download';
import codepen from 'react-useanimations/lib/codepen';
import Swal from 'sweetalert2'

let restoreCurrent = null;
let backupCurrent = null;
let downloadCurrent = null;
let withCache = false;

function SoftwareBackupVolumeComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [dataCache, setDataCache] = React.useState([]);

    // Redux
    const dispatch = useDispatch();

    // Router
    const {software} = useParams();

    // Tables
    const customSort = (order) => {
        return (obj1, obj2) => {
            const val1 = obj1.data.props.sort;
            const val2 = obj2.data.props.sort;

            if (val1 < val2) {
                return order === 'asc' ? -1 : 1;
            }
            if (val1 > val2) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        };
    };
    const columns = [
        {
            name: "filename",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "author",
            label: "Auteur",
            options: {filter: true, sort: true}
        },
        {
            name: "size",
            label: "Taille",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "backupStateShow",
            label: "Statut",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const formatMinute = (minutes) => {
        if (minutes < 60) {
            return "Temps estimé à environ <br/>" + minutes + "min";
        }
        else {
            let heures = Math.floor(minutes / 60);
            return "Temps estimé à environ <br/>" + heures + " heure" + (heures > 1 ? "s" : "");
        }
    }
    const getAction = (row) => {
        let textMinuteRestoring = props.software.backupVolumeEstimatedTimeBackupRestoring ? formatMinute(props.software.backupVolumeEstimatedTimeBackupRestoring) : false;

        return (
            <Box sx={{minWidth: '74px'}}>
                <ButtonIconComponent
                    disabled={!!downloadCurrent || row.backupState !== 3}
                    background={color.colorDownloadBackground}
                    backgroundHover={color.colorDownloadBackgroundHover}
                    color={color.colorDownload}
                    icon={BrowserUpdated}
                    iconAnimation={download}
                    animation={downloadCurrent === row.uuid}
                    tooltip={'Télécharger'}
                    onClick={() => {
                        downloadCurrent = row.uuid;
                        setLoading(true);
                        withCache = true;
                        dispatch(tableReduxReload({id: 'api_administrator_backup_volumes'}));
                        routingApiHandlerFetch({
                            route: 'api_administrator_backup_volume_download',
                            params: {software: row.software, backupVolume: row.uuid},
                            method: 'DOWNLOAD',
                            filename: row.filename,
                            done: () => {
                                downloadCurrent = null;
                                setLoading(false);
                                dispatch(tableReduxReload({id: 'api_administrator_backup_volumes'}));
                                withCache = false;
                            }
                        });
                    }}
                />
                <ButtonIconComponent
                    sx={{marginLeft: '10px'}}
                    disabled={!!backupCurrent || !!restoreCurrent || !!downloadCurrent || row.backupState !== 3}
                    background={color.colorRestoreBackground}
                    backgroundHover={color.colorRestoreBackgroundHover}
                    color={color.colorRestore}
                    icon={Restore}
                    iconAnimation={codepen}
                    animation={restoreCurrent === row.uuid}
                    tooltip={'Restaurer'}
                    onClick={() => {
                        Swal.fire({
                            title: 'Confirmation de restauration',
                            html: '<p>' +
                                'Êtes-vous sûr de vouloir restaurer le volume avec la sauvegarde sélectionnée ? <br/><br/>' +
                                (textMinuteRestoring ? ('<span style="font-size: 13px; color: #FFC107">' + textMinuteRestoring + '<br/><br/></span>') : '') +
                                '<span style="font-size: 14px">Cette action remplacera toutes les données actuelles par celles de la sauvegarde et ne pourra pas être annulée. <br/>' +
                                'Assurez-vous d\'avoir fait un backup au préalable.</span>' +
                                '</p>',
                            icon: 'warning',
                            showCancelButton: true,
                            color: color.textPrimary,
                            background: color.backgroundPrimary,
                            confirmButtonColor: color.colorConfirm,
                            cancelButtonColor: color.colorCancel,
                            confirmButtonText: 'Restaurer',
                            cancelButtonText: 'Annuler'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                restoreCurrent = row.uuid;
                                setLoading(true);
                                dispatch(tableReduxReload({id: 'api_administrator_backup_volumes'}))
                                routingApiHandlerFetch({
                                    route: 'api_administrator_backup_volume_restore',
                                    params: {software: row.software, backupVolume: row.uuid},
                                    method: 'GET',
                                    success: () => {
                                        setTimeout(() => dispatch(tableReduxReload({id: 'api_administrator_backup_volumes'})), 50);
                                        restoreCurrent = null;
                                        setLoading(false);
                                        withCache = false;
                                    },
                                    error: (response) => {
                                        if (response.code === 400) {
                                            dispatch(snackbarReduxOpen({text: response.error.message, variant: 'warning'}));
                                        }
                                        else {
                                            dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                        }
                                        restoreCurrent = null;
                                        setLoading(false);
                                        dispatch(tableReduxReload({id: 'api_administrator_backup_volumes'}));
                                    }
                                });
                            }
                        });
                    }}
                />
            </Box>
        );
    };

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box>
                <TableComponent
                    id={'api_administrator_backup_volumes'}
                    title={'Backups - Volumes'}
                    columns={columns}
                    height={'calc(100vh - 630px)'}
                    refreshInterval={5000}
                    icon={CloudSync}
                    promiseData={(resolve) => {
                        if (withCache) {
                            let data = [...dataCache];
                            for (let index in data) {
                                data[index].actions = getAction(data[index]);
                            }
                            resolve([...data]);
                        }
                        else {
                            routingApiHandlerFetch({
                                route: 'api_administrator_backup_volumes',
                                params: {software: software ?? ''},
                                method: 'GET',
                                success: (response) => {
                                    let data = response.data;
                                    restoreCurrent = null;
                                    backupCurrent = null;
                                    setLoading(false);

                                    for (let index in data) {
                                        if (data[index].inRestoring) {
                                            restoreCurrent = data[index].uuid;
                                            setLoading(true);
                                        }
                                        if (data[index].backupState === 1 || data[index].backupState === 2) {
                                            backupCurrent = data[index].uuid;
                                            setLoading(true);
                                        }
                                    }

                                    for (let index in data) {
                                        data[index].actions = getAction(data[index]);

                                        data[index].createdAt = <span sort={data[index].createdAt}>{moment(data[index].createdAt).format('lll')}</span>;
                                        data[index].size = data[index].size ? data[index].size + ' Mo' : '-';

                                        if (data[index].backupState === 1) {
                                            data[index].backupStateShow = <Chip sort={data[index].backupState} label="En file attente" sx={{height: '26px', borderRadius: '5px', background: color.colorWarningBackground, color: color.colorWarning}}/>;
                                        }
                                        else if (data[index].backupState === 2) {
                                            data[index].backupStateShow = <Chip sort={data[index].backupState} label="En cours" sx={{height: '26px', borderRadius: '5px', background: color.colorInfoBackground, color: color.colorInfo}}/>;
                                        }
                                        else if (data[index].backupState === 3) {
                                            data[index].backupStateShow = <Chip sort={data[index].backupState} label="Terminé" sx={{height: '26px', borderRadius: '5px', background: color.colorSuccessBackground, color: color.colorSuccess}}/>;
                                        }
                                        else if (data[index].backupState === 4) {
                                            data[index].backupStateShow = <Chip sort={data[index].backupState} label="En erreur" sx={{height: '26px', borderRadius: '5px', background: color.colorErrorBackground, color: color.colorError}}/>;
                                        }
                                    }
                                    setDataCache(data);
                                    resolve(data)
                                },
                                error: () => {
                                    resolve([]);
                                }
                            });
                        }
                    }}
                    actionFirst={{
                        label: 'Lancer un backup',
                        loading: loading,
                        onClick: () => {
                            let textMinute = props.software.backupVolumeEstimatedTimeBackup ? formatMinute(props.software.backupVolumeEstimatedTimeBackup) : false;

                            Swal.fire({
                                title: 'Confirmation de backup',
                                html: '<p>' +
                                    'Êtes-vous sûr de vouloir faire un backup des volumes ? <br/><br/>' +
                                    (textMinute ? ('<span style="font-size: 13px; color: #FFC107">' + textMinute + '<br/><br/></span>') : '') +
                                    '<span style="font-size: 14px">Vous êtes sur le point de lancer un backup des volumes. <br/>Cette opération peut prendre un certain temps, selon la taille des fichiers.<br/>' +
                                    'Veuillez noter que pendant la sauvegarde, l\'application pourrait être temporairement indisponible.</span>' +
                                    '</p>',
                                icon: 'warning',
                                showCancelButton: true,
                                color: color.textPrimary,
                                background: color.backgroundPrimary,
                                confirmButtonColor: color.colorConfirm,
                                cancelButtonColor: color.colorCancel,
                                confirmButtonText: 'Backuper',
                                cancelButtonText: 'Annuler'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setLoading(true);
                                    routingApiHandlerFetch({
                                        route: 'api_administrator_backup_volume_run',
                                        params: {software: software ?? ''},
                                        method: 'GET',
                                        success: () => {
                                            setLoading(false);
                                            dispatch(tableReduxReload({id: 'api_administrator_backup_volumes'}));
                                        },
                                        error: (response) => {
                                            setLoading(false);

                                            if (response.code === 400) {
                                                dispatch(snackbarReduxOpen({text: response.error.message, variant: 'warning'}))
                                            }
                                            else {
                                                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }}
                />
            </Box>
        </Fade>
    );
}

export default SoftwareBackupVolumeComponent;
