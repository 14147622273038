import React from 'react';
import {Box} from "@mui/system";
import {CircularProgress, Fade} from "@mui/material";
import {color} from "src/Config/Theme";

function Loading() {
    return (
        <Box sx={{
            backgroundColor: color.backgroundSecondary,
            position: 'absolute',
            height: '100%',
            width: '100%',
            margin: 0,
            padding: 0,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            overflow: 'hidden',
            boxSizing: 'border-box'
        }}>
            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{
                    position: 'absolute',
                    top: 'calc(50% - 30px)',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center'
                }}>
                    <img width={50} height={50} src={'/images/logo.webp'} alt={'logo'}/><br/>
                    <CircularProgress size={'70px'} thickness={1} sx={{position: 'absolute', top: '-10px', left: '-10px', color: color.primary}}/>
                </Box>
            </Fade>
        </Box>
    );
}

export default Loading;
