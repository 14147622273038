import React from 'react'
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Box} from "@mui/system";
import {VisibilityOff, Visibility} from "@mui/icons-material";
import {color} from "src/Config/Theme";

const useStyles = {
    content: {
        position: 'relative',
        width: '100%'
    },
    listBackground: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        zIndex: 2
    },
    item: {
        zIndex: 3,
        padding: '4px 10px',
        cursor: 'pointer',
        fontSize: '12px',
        minHeight: '16px',
        lineHeight: '16px',
        color: color.textPrimary,
        '&:hover': {
            background: color.primaryOpacityMore + ' !important',
            color: color.primary,
        }
    },
    list: {
        position: 'fixed',
        marginTop: '-20px',
        paddingTop: '8px',
        paddingBottom: '8px',
        backgroundColor: '#FFF',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '0 0 5px 5px',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        zIndex: 3,
        maxHeight: '120px',
        overflow: 'scroll',
        background: color.backgroundPrimary
    }
};

function AutoCompleteComponent(props) {
    const [isFocus, setIsFocus] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [valueText, setValueText] = React.useState('');
    const inputRef = React.useRef(null);

    let input = props.handler.get(props.id);

    const onChange = (event) => {
        props.handler.setError(props.id, '');
        props.handler.setValue(props.id, '');
        setValueText(event.target.value);
        props.loadOptions(event.target.value, (opt) => setOptions(opt));
    };
    const onBlur = () => {
        setTimeout(() => setIsFocus(false), 100);
        props.handler.checkValidBy(props.id);
    };
    const onFocus = () => {
        setIsFocus(true);
    };
    const onClick = (value, label) => {
        setValueText(label);
        props.handler.setError(props.id, '');
        props.handler.setValue(props.id, value);
    };

    const style = {
        width: '100%',
        '& input': {
            cursor: input.loading ? 'wait' : input.disabled ? 'not-allowed' : 'pointer',
            padding: '10px 14px',
            fontSize: 14,
            color: color.textPrimary,
            WebkitTextFillColor: color.textPrimary + ' !important;',
            caretColor: color.textPrimary
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 14
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 9px) scale(1)',
            fontSize: 14
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -7px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '5px 14px',
            fontSize: 10,
            minHeight: 10,
            lineHeight: '10px',
            color: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textTertiary + ' !important'
        },
        '&:hover .MuiFormHelperText-root': {
            color: (input.loading || input.disabled) ? color.textTertiary + ' !important' : isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textPrimary + ' !important'
        },
        '& fieldset': {
            borderWidth: '1px !important',
            borderColor: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textTertiary + ' !important',
            // boxShadow: isFocus ? ('0px 0px 2px 2px ' + color.primary) : 'none'
        },
        '&:hover fieldset': {
            borderColor: (input.loading || input.disabled) ? color.textTertiary + ' !important' : isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textPrimary + ' !important',
        },
        '& label': {
            color: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textPrimary + ' !important'
        }
    };

    // Dynamique

    return (
        <Box sx={useStyles.content}>
            <TextField
                inputRef={inputRef}
                id={input.id}
                value={valueText}
                disabled={input.disabled}
                label={input.label}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                sx={style}
                variant="outlined"
                autoComplete={(showPassword || input.type !== 'password') ? '' : 'current-password'}
                helperText={isFocus ? input.helperText : input.error ? input.error : (input.helperText + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? '' : ' (Optionnel)'))}
                type={(showPassword || input.type !== 'password') ? 'text' : 'password'}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && props.onSubmit) {
                        props.onSubmit();
                    }
                }}
                InputProps={props.adornmentOrientation && props.adornmentOrientation === 'left' ? {
                        startAdornment: input.type === 'password' ? (
                            <InputAdornment position="end" disabled={input.disabled}>
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    disabled={input.disabled}
                                >
                                    {showPassword ? <Visibility sx={{fontSize: 18, color: color.textPrimary}}/> : <VisibilityOff sx={{fontSize: 18, color: color.textPrimary}}/>}
                                </IconButton>
                            </InputAdornment>
                        ) : props.adornment ? props.adornment : <></>
                    } :
                    {
                        endAdornment: input.type === 'password' ? (
                            <InputAdornment position="end" disabled={input.disabled}>
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    disabled={input.disabled}
                                >
                                    {showPassword ? <Visibility sx={{fontSize: 18, color: color.textPrimary}}/> : <VisibilityOff sx={{fontSize: 18, color: color.textPrimary}}/>}
                                </IconButton>
                            </InputAdornment>
                        ) : props.adornment ? props.adornment : <></>
                    }}
            />

            {
                (!!input.value && isFocus && options.length > 0) &&
                <>
                    <Box sx={useStyles.listBackground}/>
                    <Box sx={{
                        ...useStyles.list,
                        ...(inputRef.current && inputRef.current.offsetWidth ? {width: (inputRef.current.offsetWidth + 14) + 'px'} : {})
                    }}>
                        {
                            options.map((option) => {
                                return <Box
                                    key={option.value}
                                    sx={useStyles.item}
                                    onClick={() => onClick(option.value, option.label)}>
                                    {option.label}
                                </Box>;
                            })
                        }
                    </Box>
                </>
            }
        </Box>
    )
}

export default AutoCompleteComponent;
