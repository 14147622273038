// Layouts
import AuthenticationLayout from "src/Layout/AuthenticationLayout/AuthenticationLayout";
import MainLayout from "src/Layout/MainLayout/MainLayout";

// Views
import Login from "src/View/Login/Login";
import ForgotPassword from "src/View/ForgotPassword/ForgotPassword";
import Dashboard from "src/View/Dashboard/Dashboard";
import Vpn from "../View/Vpn/Vpn";
import Softwares from "../View/Softwares/Softwares";
import Support from "../View/Support/Support";
import Passwords from "../View/Passwords/Passwords";
import MyProfile from "../View/MyProfile/MyProfile";
import Enterprise from "../View/Enterprise/Enterprise";
import AdministratorUsers from "src/View/Administrator/Users/Users";
import AdministratorUser from "src/View/Administrator/User/User";
import AdministratorSoftwares from "../View/Administrator/Softwares/Softwares";
import AdministratorSoftware from "../View/Administrator/Software/Software";
import AdministratorEnterprises from "../View/Administrator/Enterprises/Enterprises";
import AdministratorEnterprise from "../View/Administrator/Enterprise/Enterprise";
import AdministratorServers from "../View/Administrator/Servers/Servers";
import AdministratorServer from "../View/Administrator/Server/Server";
import AdministratorSubscriptions from "../View/Administrator/Subscriptions/Subscriptions";
import AdministratorSubscription from "../View/Administrator/Subscription/Subscription";

export const routing = {
    // Authentication
    login: {path: '/login', view: Login, layout: AuthenticationLayout, roles: []},
    forgotPassword: {path: '/forgot-password', view: ForgotPassword, layout: AuthenticationLayout, roles: []},

    // Dashboard
    dashboard: {path: '/dashboard', view: Dashboard, layout: MainLayout, roles: ['ROLE_USER']},

    // Profile
    my_profile: {path: '/my-profile', view: MyProfile, layout: MainLayout, roles: ['ROLE_USER']},

    // Softwares
    softwares: {path: '/softwares', view: Softwares, layout: MainLayout, roles: ['ROLE_USER']},

    // Softwares
    servers: {path: '/servers', view: Softwares, layout: MainLayout, roles: ['ROLE_USER']},

    // VPN
    vpn: {path: '/vpn', view: Vpn, layout: MainLayout, roles: ['ROLE_VPN']},

    // Support
    support: {path: '/support', view: Support, layout: MainLayout, roles: ['ROLE_SUPPORT']},

    // Passwords
    passwords: {path: '/passwords', view: Passwords, layout: MainLayout, roles: ['ROLE_PASSWORDS']},

    // Enterprise
    enterprise: {path: '/enterprise/:enterprise?', match: /\/enterprise(\/.{36})?$/, view: Enterprise, layout: MainLayout, roles: ['ROLE_USER']},

    // Administrator
    administrator_users: {path: '/administrator/users', menuKeys: ['administrator_user'], view: AdministratorUsers, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_USER']},
    administrator_user: {path: '/administrator/user/:user?', match: /\/administrator\/user(\/.{36})?$/, view: AdministratorUser, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_USER']},

    administrator_enterprises: {path: '/administrator/enterprises', menuKeys: ['administrator_enterprise'], view: AdministratorEnterprises, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_ENTERPRISE']},
    administrator_enterprise: {path: '/administrator/enterprise/:enterprise?', match: /\/administrator\/enterprise(\/.{36})?$/, view: AdministratorEnterprise, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_ENTERPRISE']},

    administrator_servers: {path: '/administrator/servers', menuKeys: ['administrator_server'], view: AdministratorServers, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SERVER']},
    administrator_server: {path: '/administrator/server/:server?', match: /\/administrator\/server(\/.{36})?$/, view: AdministratorServer, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SERVER']},

    administrator_subscriptions: {path: '/administrator/subscriptions', menuKeys: ['administrator_subscription'], view: AdministratorSubscriptions, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SUBSCRIPTION']},
    administrator_subscription: {path: '/administrator/subscription/:subscription?', match: /\/administrator\/subscription(\/.{36})?$/, view: AdministratorSubscription, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SUBSCRIPTION']},

    administrator_softwares: {path: '/administrator/softwares', menuKeys: ['administrator_software'], view: AdministratorSoftwares, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SOFTWARE']},
    administrator_software: {path: '/administrator/software/:software?', match: /\/administrator\/software(\/.{36})?$/, view: AdministratorSoftware, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SOFTWARE']},
};
