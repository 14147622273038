import * as React from 'react';
import {styled} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {color} from "src/Config/Theme";

const StyledTabs = styled((props) => (<Tabs{...props} TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}/>))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: color.primary,
    },
});

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            sx={{width: '100%', height: 'calc(100% - 58px)', boxSizing: 'border-box', position: 'relative', marginTop: '10px'}}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<>{children}</>)}
        </Box>
    );
}

export default function TabsComponent(props) {
    const [value, setValue] = React.useState(props.tabDefault ? props.tabDefault : '');

    let tabs = props.tabs ? props.tabs : [];
    let tabPanels = Array.isArray(props.children) ? props.children : [props.children];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%', height: '100%'}}>
            <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
            >
                {
                    tabs
                        .filter((tab) => !tab.hidden)
                        .map((tab) => {
                            return (
                                <Tab
                                    key={tab.key}
                                    value={tab.key}
                                    label={tab.label}
                                    disabled={tab.disabled}
                                    wrapped={tab.wrapped}
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        marginRight: '10px',
                                        color: color.textSecondary,
                                        '&.Mui-selected': {
                                            color: color.textPrimaryActive,
                                        }
                                    }}
                                />
                            )
                        })
                }
            </StyledTabs>
            {
                tabPanels.map((tabPanel, key) => {
                    return (
                        <TabPanel key={key} value={value} index={tabPanel.key}>
                            {tabPanel}
                        </TabPanel>
                    )
                })
            }
        </Box>
    );
}
