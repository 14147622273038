import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import LoaderPageComponent from "src/Component/LoaderPageComponent";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import SoftwareTabsComponent from "./Component/SoftwareTabsComponent";

function Software() {
    // React
    const [loading, setLoading] = React.useState(true);
    const [softwareInfo, setSoftwareInfo] = React.useState(null);

    // Router
    const {software} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Bar + Info
    React.useEffect(() => {
        if (software) {
            dispatch(barMainLayoutReduxChange({title: 'Éditer un logiciel', urlBack: routingHandlerGetRoutePathname('administrator_softwares')}));

            routingApiHandlerFetch({
                route: 'api_administrator_software',
                params: {software: software},
                method: 'GET',
                success: (response) => {
                    setSoftwareInfo(response.data);
                    dispatch(barMainLayoutReduxChange({title: 'Éditer un logiciel - ' + response.data.name, urlBack: routingHandlerGetRoutePathname('administrator_softwares')}));
                    setTimeout(() => setLoading(false), 750);
                },
                error: () => {
                    setTimeout(() => setLoading(false), 750);
                }
            });
        }
        else {
            dispatch(barMainLayoutReduxChange({title: 'Ajouter un logiciel', urlBack: routingHandlerGetRoutePathname('administrator_softwares')}));
            setLoading(false);
        }
    }, [software]);

    return (
        loading ? <LoaderPageComponent/> : <><SoftwareTabsComponent setSoftware={setSoftwareInfo} software={softwareInfo}/></>
    );
}

export default Software;
