import React from 'react';
import ReactApexChart from "react-apexcharts";
import {Box, Fade} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";

function ServerStatDiskComponent(props) {
    // React
    const [series, setSeries] = React.useState([0]);
    const options = {
        chart: {
            foreColor: "#fff",
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
            },
        },
        labels: ['Utilisé'],
        title: {
            text: 'Disque',
            align: 'left',
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#6F61F0FF"],
    };

    React.useEffect(() => {
        if (props.server) {
            routingApiHandlerFetch({
                route: 'api_administrator_server_log_disk',
                params: {server: props.server.uuid},
                method: 'GET',
                success: (response) => {
                    let sum = response.data.used ? Math.round((response.data.used / (response.data.free + response.data.used) * 100)) : 0;
                    setSeries([sum]);
                },
                error: () => {
                }
            });

            const loopInterval = setInterval(() => {
                routingApiHandlerFetch({
                    route: 'api_administrator_server_log_disk',
                    params: {server: props.server.uuid},
                    method: 'GET',
                    success: (response) => {
                        let sum = response.data.used ? Math.round((response.data.used / (response.data.free + response.data.used) * 100)) : 0;
                        setSeries([sum]);
                    },
                    error: () => {
                    }
                });
            }, 1000 * 60);

            return () => {
                clearInterval(loopInterval);
            };
        }
    }, [props.server]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent sx={{
                    height: '339px',
                    '& > div': {
                        height: '100% !important',
                        '& > div': {
                            height: '100% !important',
                            '& > svg': {
                                height: '100% !important',
                            }
                        }
                    }
                }}>
                    <ReactApexChart options={options} series={series} type={'radialBar'} height={'400px'}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default ServerStatDiskComponent;
