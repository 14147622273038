import React from 'react';
import {Box} from "@mui/system";
import TableComponent from "src/Component/TableComponent";
import {routingApiHandlerFetch, routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import {useDispatch} from "react-redux";
import {Edit, Article, Insights, Euro, AccountBalance, AccountBalanceWallet} from "@mui/icons-material";
import {Link} from "react-router-dom";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import {color} from "src/Config/Theme";
import {Grid, Typography} from "@mui/material";
import moment from 'moment';
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";

const useStyles = {
    avatar: {
        background: color.primaryOpacity,
        borderRadius: '50%',
        overflow: 'hidden',
        fontSize: '12px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: '2px',
        marginLeft: '25px',
    }
};

function Subscriptions() {
    // React
    const dispatch = useDispatch();

    // Tables
    const customSort = (order) => {
        return (obj1, obj2) => {
            const val1 = obj1.data.props.sort;
            const val2 = obj2.data.props.sort;

            if (val1 < val2) {
                return order === 'asc' ? -1 : 1;
            }
            if (val1 > val2) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        };
    };
    const columns = [
        {
            name: "image",
            label: " ",
            options: {filter: true, sort: false}
        },
        {
            name: "type",
            label: "Type",
            options: {filter: true, sort: true}
        },
        {
            name: "label",
            label: "Abonnement",
            options: {filter: true, sort: true}
        },
        {
            name: "enterprise",
            label: "Entreprise",
            options: {filter: true, sort: true}
        },
        {
            name: "prixHT",
            label: "Prix HT",
            options: {filter: true, sort: true}
        },
        {
            name: "prixTTC",
            label: "Prix TTC",
            options: {filter: true, sort: true}
        },
        {
            name: "TVA",
            label: "TVA",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Crée le",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Link to={routingHandlerGetRoutePathname('administrator_subscription', {subscription: row.uuid})}>
                    <ButtonIconComponent
                        background={color.colorEditBackground}
                        backgroundHover={color.colorEditBackgroundHover}
                        color={color.colorEdit}
                        icon={Edit}
                    />
                </Link>
            </>
        );
    };

    // Stats
    const formateNumber = (nombre) => {
        return nombre.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };
    const [stats, setStats] = React.useState({
        HTMonth: 0,
        TTCMonth: 0,
        TVAMonth: 0,
        HTYear: 0,
        TTCYear: 0,
        TVAYear: 0,
    });

    // Bar
    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'Liste des abonnements',
            canBack: false
        }));
    }, []);

    return (
        <Box>
            <ShadowBoxComponent sx={{marginBottom: '15px'}}>
                <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                    <Insights sx={{verticalAlign: 'bottom', marginRight: '15px', margin: '6px 10px 0 18px'}}/> Statistique
                </Typography>

                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={4} lg={2} xl={2} sx={{textAlign: 'center'}}>
                        <Box sx={{display: 'flex', '-webkit-box-align': 'center', alignItems: 'center', margin: '15px'}}>
                            <Box sx={{display: 'flex'}}>
                                <AccountBalanceWallet sx={{verticalAlign: 'bottom', marginRight: '15px', background: color.colorRestoreBackground, color: color.colorRestore, padding: '10px', borderRadius: '10px'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography sx={{color: color.textPrimary, fontWeight: '600'}}>
                                    {stats.HTMonth} €
                                </Typography>
                                <Typography sx={{fontSize: '12px', color: color.textPrimary}}>
                                    Prix HT / mois
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={2} xl={2} sx={{textAlign: 'center'}}>
                        <Box sx={{display: 'flex', '-webkit-box-align': 'center', alignItems: 'center', margin: '15px'}}>
                            <Box sx={{display: 'flex'}}>
                                <Euro sx={{verticalAlign: 'bottom', marginRight: '15px', background: color.colorEditBackground, color: color.colorEdit, padding: '10px', borderRadius: '10px'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography sx={{color: color.textPrimary, fontWeight: '600'}}>
                                    {stats.TTCMonth} €
                                </Typography>
                                <Typography sx={{fontSize: '12px', color: color.textPrimary}}>
                                    Prix TTC / mois
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={2} xl={2} sx={{textAlign: 'center'}}>
                        <Box sx={{display: 'flex', '-webkit-box-align': 'center', alignItems: 'center', margin: '15px'}}>
                            <Box sx={{display: 'flex'}}>
                                <AccountBalance sx={{verticalAlign: 'bottom', marginRight: '15px', background: color.colorDeleteBackground, color: color.colorDelete, padding: '10px', borderRadius: '10px'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography sx={{color: color.textPrimary, fontWeight: '600'}}>
                                    {stats.TVAMonth} €
                                </Typography>
                                <Typography sx={{fontSize: '12px', color: color.textPrimary}}>
                                    TVA / mois
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={2} xl={2} sx={{textAlign: 'center'}}>
                        <Box sx={{display: 'flex', '-webkit-box-align': 'center', alignItems: 'center', margin: '15px'}}>
                            <Box sx={{display: 'flex'}}>
                                <AccountBalanceWallet sx={{verticalAlign: 'bottom', marginRight: '15px', background: color.colorRestoreBackground, color: color.colorRestore, padding: '10px', borderRadius: '10px'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography sx={{color: color.textPrimary, fontWeight: '600'}}>
                                    {stats.HTYear} €
                                </Typography>
                                <Typography sx={{fontSize: '12px', color: color.textPrimary}}>
                                    Prix HT / an
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={2} xl={2} sx={{textAlign: 'center'}}>
                        <Box sx={{display: 'flex', '-webkit-box-align': 'center', alignItems: 'center', margin: '15px'}}>
                            <Box sx={{display: 'flex'}}>
                                <Euro sx={{verticalAlign: 'bottom', marginRight: '15px', background: color.colorEditBackground, color: color.colorEdit, padding: '10px', borderRadius: '10px'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography sx={{color: color.textPrimary, fontWeight: '600'}}>
                                    {stats.TTCYear} €
                                </Typography>
                                <Typography sx={{fontSize: '12px', color: color.textPrimary}}>
                                    Prix TTC / an
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={2} xl={2} sx={{textAlign: 'center'}}>
                        <Box sx={{display: 'flex', '-webkit-box-align': 'center', alignItems: 'center', margin: '15px'}}>
                            <Box sx={{display: 'flex'}}>
                                <AccountBalance sx={{verticalAlign: 'bottom', marginRight: '15px', background: color.colorDeleteBackground, color: color.colorDelete, padding: '10px', borderRadius: '10px'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography sx={{color: color.textPrimary, fontWeight: '600'}}>
                                    {stats.TVAYear} €
                                </Typography>
                                <Typography sx={{fontSize: '12px', color: color.textPrimary}}>
                                    TVA / an
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ShadowBoxComponent>

            <TableComponent
                id={'api_administrator_subscriptions'}
                title={'Abonnements'}
                columns={columns}
                height={'calc(100vh - 373px)'}
                refreshInterval={5000}
                icon={Article}
                promiseData={(resolve) => {
                    routingApiHandlerFetch({
                        route: 'api_administrator_subscriptions',
                        method: 'GET',
                        success: (response) => {
                            let data = response.data;
                            let s = {
                                HTMonth: 0,
                                TTCMonth: 0,
                                TVAMonth: 0,
                                HTYear: 0,
                                TTCYear: 0,
                                TVAYear: 0,
                            };

                            for (let index in data) {
                                // Stats
                                s.HTMonth += data[index].prixHT;
                                s.TTCMonth += data[index].prixTTC;
                                s.TVAMonth = s.TTCMonth - s.HTMonth;

                                data[index].actions = getAction(data[index]);
                                data[index].createdAt = <span sort={data[index].createdAt}>{moment(data[index].createdAt).format('lll')}</span>;
                                data[index].image = data[index].image ? <img
                                    sort={data[index].type}
                                    style={{marginTop: '2px', marginLeft: '25px', borderRadius: '25px'}}
                                    src={data[index].image ? routingApiHandlerGetRoute({route: 'api_administrator_subscriptions_image', params: {image: data[index].image}}) : ''}
                                    alt={data.name}
                                    height={28}
                                    width={28}/> : <Box sort={data[index].enterprise} sx={useStyles.avatar}>
                                    <Typography>{data[index].name[0]}</Typography>
                                </Box>;
                            }

                            s = {
                                HTMonth: formateNumber(s.HTMonth),
                                TTCMonth: formateNumber(s.TTCMonth),
                                TVAMonth: formateNumber(s.TVAMonth),
                                HTYear: formateNumber(s.HTMonth * 12),
                                TTCYear: formateNumber(s.TTCMonth * 12),
                                TVAYear: formateNumber(s.TVAMonth * 12),
                            };

                            setStats(s);
                            resolve(data);
                        },
                        error: () => {
                            resolve([]);
                        }
                    });
                }}
                actionFirst={{
                    label: 'Ajouter un abonnement',
                    link: routingHandlerGetRoutePathname('administrator_subscription', {subscription: null})
                }}
            />
        </Box>
    );
}

export default Subscriptions;
