import React from 'react';
import {Box, Fade, Typography} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Security} from "@mui/icons-material";
import ButtonComponent from "src/Component/ButtonComponent";
import AceEditor from 'react-ace';
import {routingApiHandlerFetch} from "../../../../Handler/RoutingApiHandler";
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import {snackbarReduxOpen} from "../../../../Redux/snackbarRedux";
import {useDispatch} from "react-redux";

function ServerFirewallComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [code, setCode] = React.useState('');

    // Redux
    const dispatch = useDispatch();

    // Form
    const onChange = (newValue) => {
        setCode(newValue);
    };
    const save = () => {
        setLoading(true);

        routingApiHandlerFetch({
            route: 'api_administrator_server_firewall',
            method: 'POST',
            params: {server: props.server.uuid},
            data: {scriptFirewall: code},
            success: () => {
                setLoading(false);
                dispatch(snackbarReduxOpen({text: 'Firewall enregistré avec succès.', variant: 'success'}))
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                setLoading(false);
            }
        });
    };

    React.useEffect(() => {
        setLoading(true);

        if (props.server) {
            routingApiHandlerFetch({
                route: 'api_administrator_server_firewall',
                params: {server: props.server.uuid},
                method: 'GET',
                success: (response) => {
                    setCode(response.data.scriptFirewall);
                    setTimeout(() => setLoading(false), 750);
                },
                error: () => {
                    setTimeout(() => setLoading(false), 750);
                }
            });
        }
        else {
            setLoading(false);
        }
    }, [props.server]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                        <Security sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Iptables
                    </Typography>

                    <br/>

                    <AceEditor
                        style={{width: '100%', height: 'calc(100vh - 300px)'}}
                        mode="ruby"
                        theme="solarized_dark"
                        onChange={onChange}
                        name="firewall"
                        editorProps={{$blockScrolling: true}}
                        value={code}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                    />

                    <br/>

                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default ServerFirewallComponent;
